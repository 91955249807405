import * as React from "react"
import styled from "styled-components"

import { Icon } from "../Icon/Icon"
interface Props {
  onDelete: () => void
}

export const FilterChip: React.FC<Props> = ({ onDelete, children }) => (
  <Chip>
    <ChipCloseButton onClick={() => onDelete()}>
      <StyledIcon size={17} icon="lomake_sulje_harmaa" />
    </ChipCloseButton>
    <ChipText>{children}</ChipText>
  </Chip>
)

const StyledIcon = styled(Icon)`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 22px !important;
    height: 22px !important;
    font-size: 22px !important;
    line-height: 22px !important;
  }
`

const Chip = styled.div`
  height: 25px;
  border: 1px solid ${({ theme }) => theme.color.gray};
  border-radius: 12.5px;
  text-align: center;
  line-height: 25px;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ChipCloseButton = styled.button`
  border: none;
  background: transparent;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const ChipText = styled.div`
  padding: 0 15px;
  color: ${({ theme }) => theme.color.gray};
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-weight: ${({ theme }) => theme.weight.semiBold};
`
