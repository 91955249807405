export const theme = {
  borderRadius: "3px",
  color: {
    primaryDark: "#183D67",
    primary: "#1572FE",
    black: "#000000",
    white: "#FFFFFF",
    background: "#F8F8F8",
    gray: "#686868",
    lightGray: "#E0E0E0",
    red: "#fc4242",
  },
  fontFamily: "'Montserrat', sans-serif",
  fontSize: {
    extraTiny: "9px",
    tiny: "11px",
    extraSmall: "12px",
    small: "14px",
    mediumSmall: "16px",
    medium: "18px",
    large: "24px",
    extraLarge: "28px",
    heading: "36px",
  },
  weight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  breakpoints: {
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
} as const

export type Theme = typeof theme
export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl"
