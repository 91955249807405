import styled from "styled-components"
import icons from "../../icons"

interface IconProps {
  icon: string
  size: number
}

export const IconButton = styled.img.attrs((props: IconProps) => ({
  //@ts-ignore
  src: icons[props.icon],
}))<IconProps>`
  && {
    height: 1.5em;
    font-size: 1.6em;
    width: 1.5em;
    line-height: 1.6em;
    background: ${({ theme }) => theme.color.primary};
    border-radius: 0.8em;
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px #888;
    padding: 0.1em 0.3em;
    cursor: pointer;
  }
`
