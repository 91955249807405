import ClipLoader from "react-spinners/ClipLoader"
import { css } from "@emotion/core"

const override = css`
  display: block;
  margin: 0 auto;
`

export const Loading = () => {
  return <ClipLoader loading={true} css={override} size={150} />
}
