import checkmark from "./checkmark.svg"
import dropdown from "./dropdown.svg"
import hae from "./hae.svg"
import lomake_sulje from "./lomake_sulje.svg"
import lomake_sulje_harmaa from "./lomake_sulje_harmaa.svg"
import lomake_upload from "./lomake_upload.svg"
import mobiilinavi_burger from "./mobiilinavi_burger.svg"
import mobiilinavi_sulje from "./mobiilinavi_sulje.svg"
import mobiilinavi_sulje_dark from "./mobiilinavi_sulje_dark.svg"
import nuoli_kalenteri_oikea from "./nuoli_kalenteri_oikea.svg"
import nuoli_kalenteri_vasen from "./nuoli_kalenteri_vasen.svg"
import nuoli_linkki_oikea from "./nuoli_linkki_oikea.svg"
import nuoli_linkki_oikea_sininen from "./nuoli_linkki_oikea_sininen.svg"
import nuoli_linkki_vasen from "./nuoli_linkki_vasen.svg"
import nuoli_linkki_vasen_sininen from "./nuoli_linkki_vasen_sininen.svg"
import social_facebook from "./social_facebook.png"
import social_instagram from "./social_instagram.png"
import social_linkedin from "./social_linkedin.png"
import social_soundcloud from "./social_soundcloud.png"
import social_twitter from "./social_twitter.png"
import social_youtube from "./social_youtube.png"
import tekija_kielet from "./tekija_kielet.svg"
import tekija_saatavilla from "./tekija_saatavilla.svg"
import tekija_sijainti from "./tekija_sijainti.svg"
import tekija_tuntihinta from "./tekija_tuntihinta.svg"
import tekija_tyokokemus from "./tekija_tyokokemus.svg"
import content_copy from "./content_copy.svg"
import email from "./email.svg"
import favorite from "./favorite.svg"
import favorite_filled from "./favorite_filled.svg"
import favorite_dark from "./favorite_dark.svg"
import favorite_filled_dark from "./favorite_filled_dark.svg"

const icons = {
  checkmark,
  dropdown,
  hae,
  lomake_sulje,
  lomake_sulje_harmaa,
  lomake_upload,
  mobiilinavi_burger,
  mobiilinavi_sulje,
  mobiilinavi_sulje_dark,
  nuoli_kalenteri_oikea,
  nuoli_kalenteri_vasen,
  nuoli_linkki_oikea,
  nuoli_linkki_oikea_sininen,
  nuoli_linkki_vasen,
  nuoli_linkki_vasen_sininen,
  social_facebook,
  social_instagram,
  social_linkedin,
  social_soundcloud,
  social_twitter,
  social_youtube,
  tekija_kielet,
  tekija_saatavilla,
  tekija_sijainti,
  tekija_tuntihinta,
  tekija_tyokokemus,
  content_copy,
  email,
  favorite,
  favorite_filled,
  favorite_dark,
  favorite_filled_dark,
}

export default icons
