import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import {
  Button,
  Flex,
  Icon,
  Input,
  InputCheckBox,
  InputFile,
} from "../components"
import { postContactJoin } from "../services/developersService"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import ReCAPTCHA from "react-google-recaptcha"
import { uploadCv } from "../../../server/uploadcv"

export const Join = () => {
  const { handleSubmit, register, errors, reset } = useForm()
  const [submittedData, setSubmittedData] = React.useState({})
  const [message, setMessage] = useState("")
  const [showMessage, setShowMessage] = useState(false)
  const didMountRef = useRef(false)
  const [t, i18n] = useTranslation("common")
  const recaptchaRef = React.createRef<ReCAPTCHA>()

  useEffect(() => {
    if (didMountRef.current) {
      send()
    } else {
      didMountRef.current = true
    }
  }, [submittedData])

  const onSubmit = (data: React.SetStateAction<{}>) => {
    setSubmittedData(data)
  }

  const send = async () => {
    const data: any = submittedData
    data.language = i18n.language
    const recaptchaValue = recaptchaRef.current?.getValue()

    if (!recaptchaValue) {
      setMessage(t("form.emptyCaptcha"))
      setShowMessage(true)
    } else {
      data.captcha = recaptchaValue
      // Upload CV to AWS S3
      const uploadRes = await uploadCv(data.cv["0"])
      if (uploadRes.status === 204) {
        data.cv = uploadRes.location
        data.mista_kuulit_meista_ = "."
        const response = await postContactJoin(data)

        if (response.status === 200) {
          setMessage(t("form.successResponse"))
          setShowMessage(true)
          window.history.replaceState({}, "", "/join?joinform=ok")
          import("react-facebook-pixel")
            .then((module) => module.default)
            .then((ReactPixel) => {
              ReactPixel.track("SubmitApplication", data)
            })
          reset()
        } else if (response.status === 400) {
          const error = (response.data.errors && response.data.errors[0]) || {
            errorType: null,
          }
          if (error.errorType === "INVALID_EMAIL") {
            setMessage(t("form.emailInvalidResponse"))
            setShowMessage(true)
          } else {
            setMessage(t("form.errorSendingResponse"))
            setShowMessage(true)
          }
        } else {
          setMessage(t("form.retryResponse"))
          setShowMessage(true)
        }
      } else {
        setMessage(t("form.errorSendingResponse"))
        setShowMessage(true)
      }
    }
  }

  return (
    <Flex row justify="center">
      <Flex column paddingBottom="35.52px" style={{ maxWidth: 800 }}>
        <Title>{t("join.title2")}</Title>
        <InfoText>{t("join.infoText")}</InfoText>
        <QuestionText>{t("join.question1")}</QuestionText>
        <AnswerText>{t("join.answer1")}</AnswerText>
        <QuestionText>{t("join.question4")}</QuestionText>
        <AnswerText>
          {t("join.answer4.part1")}
          <br />
          <br />
          <ul>
            <li>{t("join.answer4.part2")}</li>
            <li>{t("join.answer4.part3")}</li>
            <li>{t("join.answer4.part4")}</li>
            <li>{t("join.answer4.part5")}</li>
          </ul>
        </AnswerText>
        <QuestionText>{t("join.question2")}</QuestionText>
        <AnswerText>
          {t("join.answer2.part1")}
          <br />
          <br />
          {t("join.answer2.part2")}
          <br />
          <br />
          <h3>{t("join.answer2.part3")}</h3>
          <br />
          <br />
          {t("join.answer2.part4")} <strong>85 €</strong>
          <br />
          <br />
          <strong>{t("join.answer2.part5")}</strong>
          <br />
          <br />
          {t("join.answer2.part6")} <strong>95 €</strong>
          <br />
          <br />
          {t("join.answer2.part7")}
          <br />
          <br />
          {t("join.answer2.part8")}
        </AnswerText>
        <QuestionText>{t("join.question3")}</QuestionText>
        <AnswerText>
          <ul>
            <li>{t("join.answer3.part1")}</li>
            <li>{t("join.answer3.part2")}</li>
            <li>{t("join.answer3.part3")}</li>
            <li>{t("join.answer3.part4")}</li>
          </ul>
        </AnswerText>
        <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: "550px" }}>
          <Flex column spacing="30px">
            <FormTitle>{t("join.formTitle")}</FormTitle>
            <FormField label={t("form.firstNameLabel")}>
              <FormErrorText>{errors.firstName?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.firstNamePlaceHolder")}
                name="firstName"
                id="firstName"
                ref={register({
                  required: {
                    message: t("form.firstNameRequired"),
                    value: true,
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.lastNameLabel")}>
              <FormErrorText>{errors.lastName?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.lastNamePlaceHolder")}
                name="lastName"
                id="lastName"
                ref={register({
                  required: {
                    message: t("form.lastNameRequired"),
                    value: true,
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.emailLabel")}>
              <FormErrorText>{errors.email?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.emailPlaceHolder")}
                name="email"
                id="email"
                ref={register({
                  required: {
                    message: t("form.emailRequired"),
                    value: true,
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("form.emailInvalid"),
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.phoneNumberLabel")}>
              <FormErrorText>{errors.phoneNumber?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.phoneNumberPlaceHolder")}
                name="phoneNumber"
                id="phoneNumber"
                ref={register({
                  required: {
                    message: t("form.phoneNumberRequired"),
                    value: true,
                  },
                  pattern: {
                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                    message: t("form.phoneNumberInvalid"),
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.linkedInLabel")}>
              <FormErrorText>{errors.linkedIn?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.linkedInLabel")}
                name="linkedIn"
                id="linkedIn"
                ref={register({
                  pattern: {
                    value: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/[A-z0-9_-]+\/?/i,
                    message: t("form.linkedInInvalid"),
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.hourlyRateLabel")}>
              <Input
                type="number"
                placeholder={t("form.hourlyRateLabel")}
                name="hourlyRate"
                id="hourlyRate"
                ref={register({
                  pattern: {
                    value: /^[0-9]+$/i,
                    message: t("form.hourlyRateInvalid"),
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.locationLabel")}>
              <FormErrorText>{errors.location?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.locationPlaceholder")}
                name="location"
                id="location"
                ref={register({
                  required: {
                    message: t("form.locationRequired"),
                    value: true,
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.cvLabel")}>
              <FormErrorText>{errors.cv?.message}</FormErrorText>
              <InputFile
                type="file"
                name="cv"
                id="cv"
                ref={register({
                  required: {
                    message: t("form.cvRequired"),
                    value: true,
                  },
                  validate: {
                    lessThan10MB: (files) =>
                      files[0]?.size < 10000000 || "Max 10MB",
                    acceptedFormats: (files) =>
                      [
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "text/plain",
                        "application/msword",
                      ].includes(files[0]?.type) ||
                      String(t("form.cvFileTypeInvalid")),
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.userAgreement")}>
              <FormErrorText>{errors.userAgreement?.message}</FormErrorText>
              <InputCheckBox
                type="checkbox"
                name="userAgreement"
                id="userAgreement"
                ref={register({
                  required: {
                    message: t("form.userAgreementRequired"),
                    value: true,
                  },
                })}
              />
            </FormField>
            <FormField label={t("form.marketingAgreement")}>
              <InputCheckBox
                type="checkbox"
                name="marketingAgreement"
                id="marketingAgreement"
                ref={register}
              />
            </FormField>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
            />
            <Button variant2 type="submit" style={{ width: 200 }}>
              <div>{t("form.sendLabel")}</div>
              <Icon icon="nuoli_linkki_oikea" size={20} />
            </Button>
            {showMessage && (
              <FormLabelText
                dangerouslySetInnerHTML={{ __html: message }}
                id="joinform"
              />
            )}
          </Flex>
        </form>
      </Flex>
    </Flex>
  )
}

const Title = styled.h1`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.heading};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 44px;
  max-width: 550px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.extraLarge};
    font-weight: ${({ theme }) => theme.weight.bold};
    line-height: 34px;
    max-width: 325px;
  }
`
const InfoText = styled.div`
  margin-top: 20.26px;
  margin-bottom: 40px;

  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 17px;
`

const QuestionText = styled.h3`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 22px;
  max-width: 550px;
  margin-top: 30px;
`

const AnswerText = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.weight.regular};
  line-height: 17.07px;
  max-width: 550px;
  margin-top: 20px;
`

const FormTitle = styled.h2`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 44px;
`

interface FormFieldProps {
  label: string
}

const FormField: React.FC<FormFieldProps> = (props) => (
  <FormLabel>
    <FormLabelText>{props.label}</FormLabelText>
    {props.children}
  </FormLabel>
)

const FormLabelText = styled.div`
  margin-bottom: 10px;
`

const FormErrorText = styled.div`
  margin-bottom: 10px;
  color: red;
`

const FormLabel = styled.label`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 13.41px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`
