import styled, { css } from "styled-components"

import { theme } from "../../theme"

type DefinedBreakpoint = keyof typeof theme["breakpoints"]

interface FromProps {
  from: DefinedBreakpoint
  to?: undefined
}

interface ToProps {
  to: DefinedBreakpoint
  from?: undefined
}

interface BothProps {
  from: DefinedBreakpoint
  to: DefinedBreakpoint
}

type VisibilityProps = FromProps | ToProps | BothProps

export const Hidden = styled.div<VisibilityProps>(
  (props: VisibilityProps) => css`
    display: contents;
    @media ${getMediaQuery(props)} {
      display: none;
    }
  `
)

export const Visible = styled.div<VisibilityProps>(
  (props: VisibilityProps) => css`
    display: none;
    @media ${getMediaQuery(props)} {
      display: contents;
    }
  `
)

const getMediaQuery = (props: VisibilityProps) => {
  const rules = [
    props.from && getRule("min", props.from),
    props.to && getRule("max", props.to),
  ]
    .filter((item) => !!item)
    .join(" and ")

  return rules
}

const getRule = (type: "min" | "max", breakpoint: DefinedBreakpoint) =>
  `(${type}-width: ${theme.breakpoints[breakpoint]}px)`
