import React from "react"
import styled from "styled-components"

interface TextContainerProps {
  checked: boolean
}

const TextContainer = styled.div.attrs<TextContainerProps>({
  type: "checkbox",
})`
  margin-left: 16px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.black};
  font-weight: ${(props: any) =>
    props.checked ? props.theme.weight.bold : props.theme.weight.medium};
`

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
}

export const Checkbox = ({
  label,
  type,
  children,
  ...remainingProps
}: CheckboxProps) => {
  return (
    <StyledLabel>
      <StyledCheckbox {...remainingProps} />
      <TextContainer>{label}</TextContainer>
    </StyledLabel>
  )
}

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  width: 25px;
  height: 25px;
`
