import React from "react"
import styled from "styled-components"

const CollapseContainer = styled.div`
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 0px;
  overflow-y: hidden;
`

interface Props {
  open: boolean
  className?: string
}

export const Collapse: React.FC<Props> = ({ children, open, className }) => {
  const contentRef = React.useRef<HTMLDivElement>(null)
  const containerRef = React.useRef<any>(null)
  const initialRender = React.useRef(true)

  React.useEffect(() => {
    const container = containerRef.current
    if (!container) {
      throw new Error("Container not found")
    }
    const content = contentRef.current
    if (!content) {
      throw new Error("Content not found")
    }

    if (initialRender.current) {
      initialRender.current = false
      container.style.border = 0
    } else {
      const contentHeight = content.getBoundingClientRect().height
      if (open) {
        // Collapse opens
        container.style["overflow-y"] = contentHeight > 390 ? "auto" : "hidden"
        container.style.border = null
        container.style.height =
          contentHeight > 390 ? "390px" : `${contentHeight}px`
      } else {
        // Collapse closes (animates from content height to 0)
        container.style.height =
          contentHeight > 390 ? "390px" : `${contentHeight}px`
        requestAnimationFrame(() => {
          container.style.height = "0px"
        })
      }
    }
  }, [open])

  return (
    <CollapseContainer
      ref={containerRef}
      className={className}
      onTransitionEnd={(e) => {
        const container = containerRef.current
        if (!container) {
          return
        }
        if (e.target !== container) {
          return
        }
        if (!open) {
          container.style.border = 0
        }
      }}
    >
      <div ref={contentRef}>{children}</div>
    </CollapseContainer>
  )
}
