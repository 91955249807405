import { Link, Route } from "react-router-dom"
import styled from "styled-components"
import { Icon } from "./components"
import { useTranslation } from "react-i18next"
import React, { useState } from "react"
import { ProcessedProfile } from "./processData"

const StyledLink = styled(Link)`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
  display: flex;

  padding-left: 60px;
  padding-right: 60px;
  background: ${({ theme }) => theme.color.primary};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 70px;

  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
const MobileContactText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.weight.bold};
  line-height: 17px;
  width: 500px;
  margin-right: 20px;
`

export const MobileContactLink = () => {
  const [t] = useTranslation("common")
  const [favorites, setFavorites] = useState([] as ProcessedProfile[])

  React.useEffect(() => {
    updateFavorite()
    window.addEventListener("storage", updateFavorite)
    return () => {
      window.removeEventListener("storage", updateFavorite)
    }
  }, [])

  const updateFavorite = () => {
    setFavorites(
      JSON.parse(
        localStorage.getItem("favorites") || "[]"
      ) as ProcessedProfile[]
    )
  }

  return (
    <Route exact path="/">
      <StyledLink to="/contact">
        <MobileContactText>
          {t("searchRow.contactButtonLabel", {
            count: favorites.length,
            context: `${favorites.length}`,
          })}
        </MobileContactText>
        <Icon icon="nuoli_linkki_oikea" size={20} />
      </StyledLink>
    </Route>
  )
}
