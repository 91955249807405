import React from "react"
import styled from "styled-components"
import { createGlobalStyle } from "styled-components"
import { Route, Switch } from "react-router"
import CookieBot from "react-cookiebot"

import { Flex } from "./components"
import { Contact } from "./pages/Contact"
import { Join } from "./pages/Join"
import { FAQ } from "./pages/FAQ"
import { ProfilePage } from "./pages/ProfilePage"
import { Footer } from "./Footer"
import { Header } from "./Header"
import { Theme } from "./theme"
import { MainPage } from "./MainPage"
import { Developers } from "../types/apitypes"
import { getDevelopers } from "./services/developersService"
import { ProcessedProfile, processData } from "./processData"
//import { MOCK_DATA } from "../../client/mockData"
import { MobileContactLink } from "./MobileContactLink"
import ScrollToTop from "./ScrollToTop"
import { UnsubscribePage } from "./pages/UnsubscribePage"

const flattenDevelopers = (developers: Developers) => [
  ...developers.employees,
  ...developers.subcontractors,
]

const MainFlex = styled(Flex)`
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.sm}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.md}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.xl}px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    padding-left: 120px;
    padding-right: 120px;
  }
`

interface AppProps {
  processedProfiles?: ProcessedProfile[]
}

export const App = ({ processedProfiles }: AppProps) => {
  const [errorText, setErrorText] = React.useState("")
  const [data, setData] = React.useState(
    processedProfiles || ([] as ProcessedProfile[])
  )

  // const [data, setData] = React.useState<ProcessedProfile[] | null>(
  //   processData(MOCK_DATA)
  // )

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const developers: Developers = await getDevelopers()
        const processedData = processData(flattenDevelopers(developers)).sort(
          () => Math.random() - 0.5
        )
        setData(processedData)

        // ProfilePage has no way of knowing whether it should wait for data or if the data
        // doesn't contain any developers, thus we set errorText on empty data
        if (!processedData.length) {
          setErrorText("Empty data")
        }
      } catch (e: any) {
        setErrorText(e.message)
      }
    }

    import("react-facebook-pixel")
      .then((module) => module.default)
      .then((ReactPixel) => {
        if (process.env.REACT_APP_FACEBOOK_PIXEL_ID) {
          ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID)
          ReactPixel.revokeConsent()
          window.addEventListener("CookiebotOnAccept", () => {
            ReactPixel.grantConsent()
            ReactPixel.pageView()
          })
        }
      })
    fetchData()
  }, [])

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <CookieBot
        domainGroupId={
          process.env.REACT_APP_COOKIEBOT_ID
            ? process.env.REACT_APP_COOKIEBOT_ID
            : ""
        }
      />
      <Header />
      <MobileContactLink />
      <MainFlex column as="main" paddingTop="52.67px">
        <Switch>
          <Route path="/contact" component={Contact} />
          <Route path="/FAQ" component={FAQ} />
          <Route path="/join" component={Join} />
          <Route
            path="/profile/:title/:id"
            render={(props) =>
              data && (
                <ProfilePage
                  data={data}
                  id={Number(props.match.params.id)}
                  errorText={errorText}
                />
              )
            }
          />
          <Route
            path="/unsubscribe/:id"
            render={(props) => <UnsubscribePage id={props.match.params.id} />}
          />
          <Route
            exact
            path="/"
            render={() =>
              data && <MainPage errorText={errorText} data={data} />
            }
          />
        </Switch>
      </MainFlex>
      <Footer />
    </>
  )
}

declare module "styled-components" {
  interface DefaultTheme extends Theme {}
}

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
}
  html, body {
    height: 100%;
  }
  body {
    margin: 0;
    background-color: ${({ theme }) => theme.color.background};
    font-family: ${({ theme }) => theme.fontFamily};
  }

  h1, h2, h3, h4 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
`
