import ReactS3Client from "react-aws-s3-typescript"
import uuid from "uuid"

const s3Config = {
  bucketName: "vahvistus-cv",
  region: process.env.REACT_APP_AWS_S3_REGION || "",
  accessKeyId: process.env.REACT_APP_AWS_S3_KEY_ID || "",
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET || "",
}

export const uploadCv = async (data: File) => {
  const s3 = new ReactS3Client(s3Config)
  const id = uuid.v4()
  try {
    const res = await s3.uploadFile(data, id)
    return res
  } catch (exception) {
    throw new Error("Error")
  }
}

export const deleteCv = async (filepath: string) => {
  const s3 = new ReactS3Client(s3Config)

  try {
    await s3.deleteFile(filepath)
  } catch (exception) {
    throw new Error("Error")
  }
}
