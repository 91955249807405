import styled, { css } from "styled-components"

interface FlexProps {
  row?: boolean
  column?: boolean
  align?: string
  justify?: string
  spacing?: string
  padding?: string
  paddingTop?: string
  paddingBottom?: string
  paddingLeft?: string
  paddingRight?: string
  wrap?: boolean
  rowSpacing?: string
  flex?: string
}

/**
 * Swiss Army Knife of layout components. Contains shorthand props for most common flex attributes,
 * extends flex with spacing and row (wrap) spacing props.
 */
export const Flex = styled.div<FlexProps>`
  ${({
    row,
    column,
    align,
    justify,
    spacing,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    wrap,
    rowSpacing,
    flex,
  }) => css`
    display: flex;
    ${(row || column) && `flex-direction: ${column ? "column" : "row"};`}
    ${align && `align-items: ${align};`}
    ${justify && `justify-content: ${justify};`}
    ${wrap && `flex-wrap: wrap;`}
    ${flex && `flex: ${flex};`}
    ${paddingTop && `padding-top: ${paddingTop};`}
    ${paddingBottom && `padding-bottom: ${paddingBottom};`}
    ${paddingLeft && `padding-left: ${paddingLeft};`}
    ${paddingRight && `padding-right: ${paddingRight};`}
    ${padding && `padding: ${padding};`}
    ${(spacing || rowSpacing) && flexSpacing(spacing || "0px", rowSpacing)}
  `}
`

export const flexSpacing = (spacing: string, rowSpacing?: string) =>
  css`
    ${({ column, wrap }: FlexProps) => `
  > ${wrap ? "*" : ":not(:last-child)"} {
    margin-${column ? "bottom" : "right"}: ${spacing};
  }${
    rowSpacing &&
    `
      margin-top: -${rowSpacing};
      > * {
        margin-top: ${rowSpacing};
      }
    `
  }
  `}
  `
