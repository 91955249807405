import styled from "styled-components"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Button, Flex, Icon } from "./components"

export const Footer = () => {
  const [t] = useTranslation("common")

  return (
    <FooterArea as="footer">
      <Flex column spacing="12px">
        <FooterHeading>{t("footer.moreInfo")}</FooterHeading>
        <Button as={Link} to="/contact" variant2 style={{ width: 200 }}>
          <div>{t("common.contact")}</div>
          <Icon icon="nuoli_linkki_oikea" size={20} />
        </Button>
      </Flex>
      <ContactArea>
        <Flex column>
          <TextLink href="https://sysart.fi/">
            <Strong>Sysart Oy</Strong>
          </TextLink>
          <div>Sepänkatu 20</div>
          <div>90100 Oulu</div>
          <TextLink href="tel:+358406613857">+358 40 661 3857</TextLink>
        </Flex>
      </ContactArea>
      <Flex column spacing="29px">
        <LegaleseArea>
          <TextLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.sysart.fi/rekisteriseloste-asiakasrekisteri"
          >
            {t("footer.privacyPolicy")}
          </TextLink>
          <TextLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://tietopankki.sysart.fi/hubfs/Vahvistus/Tietosuojaseloste_Vahvistus.fi.pdf"
          >
            {t("footer.privacyStatement")}
          </TextLink>
        </LegaleseArea>
        <SocialMediaArea>
          <Flex row wrap spacing="15px" rowSpacing="15px">
            {socialMediaLink("Twitter", "https://twitter.com/sysartoy")}
            {socialMediaLink(
              "YouTube",
              "https://www.youtube.com/channel/UChldginpmHTq068Gu4qn-wA"
            )}
            {socialMediaLink(
              "LinkedIn",
              "https://linkedin.com/company/sysart-oy"
            )}
            {socialMediaLink("Facebook", "https://facebook.com/sysartoy")}
            {socialMediaLink("Instagram", "https://instagram.com/sysartoy")}
            {socialMediaLink("SoundCloud", "https://soundcloud.com/sysart")}
          </Flex>
        </SocialMediaArea>
      </Flex>
    </FooterArea>
  )
}

const LegaleseArea = styled(Flex).attrs({
  column: true,
  spacing: "15px",
})`
  font-weight: ${({ theme }) => theme.weight.extraBold};
  font-size: ${({ theme }) => theme.fontSize.tiny};
  line-height: 13px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 20px;
  }
`

const FooterArea = styled(Flex).attrs({
  row: true,
  align: "flex-start",
  spacing: "36px",
  wrap: true,
})`
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.primaryDark};
  color: ${({ theme }) => theme.color.white};
  padding-left: 120px;
  padding-top: 83.91px;
  padding-bottom: 72.83px;
  padding-right: 141px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: column;
    padding-left: 50px;
    padding-top: 60px;
    padding-bottom: 105px;
    padding-right: 50px;
  }
`

// Todo: no max width on narrow layout (all social media icons on same line)
const SocialMediaArea = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 120px;
  }
`

const socialMediaLink = (name: string, url: string) => (
  <a target="_blank" rel="noreferrer" href={url} title={name}>
    <Icon icon={`social_${name.toLowerCase()}`} size={30} />
  </a>
)

const FooterHeading = styled.h2`
  font-weight: ${({ theme }) => theme.weight.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 29px;
`

const ContactArea = styled(Flex).attrs({
  column: true,
  align: "flex-start",
  flex: "1",
  spacing: "27px",
})`
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 17px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 20px;
  }
`

const Strong = styled.strong`
  font-weight: ${({ theme }) => theme.weight.extraBold};
`

const TextLink = styled.a`
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
`
