import * as React from "react"
import { ThemeProvider } from "styled-components"
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { Link } from "react-router-dom"

import { theme } from "./theme"
import common_fi from "../../translations/fi/common.json"
import common_en from "../../translations/en/common.json"
import { SnackbarProvider } from "notistack"
import { Button } from "./components"

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  fallbackLng: "en",
  supportedLngs: ["en", "fi"],
  resources: {
    en: {
      common: common_en,
    },
    fi: {
      common: common_fi,
    },
  },
})
const style = {
  background: theme.color.primary,
  fontFamily: theme.fontFamily,
}

export const Providers: React.FC<{}> = ({ children }) => (
  <I18nextProvider i18n={i18next}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        action={
          <Button as={Link} to={`/contact`}>
            {i18next.t("common:common.showFavorites")}
          </Button>
        }
        style={style}
      >
        {children}
      </SnackbarProvider>
    </ThemeProvider>
  </I18nextProvider>
)
