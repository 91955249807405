import React from "react"
import qs from "qs"
import { DateTime } from "luxon"

import { useHistory, useLocation } from "react-router"

const getRouterStateHook = <ValueType>(
  validate: (value: string) => boolean,
  parse: (value: string) => ValueType,
  serialize: (value: ValueType) => string
) => (key: string, defaultValue: ValueType) => {
  const location = useLocation()
  const locationRef = React.useRef(location)
  locationRef.current = location

  const history = useHistory()

  const valueAndSetter = React.useMemo(() => {
    const queryParams = qs.parse(locationRef.current.search, {
      ignoreQueryPrefix: true,
    })
    //@ts-ignore
    const valueFromQuery: string | undefined = queryParams[key]
    const value: ValueType =
      valueFromQuery && validate(valueFromQuery)
        ? parse(valueFromQuery)
        : defaultValue

    const setValue: (action: React.SetStateAction<ValueType>) => void = (
      action: any
    ) => {
      const queryParams = qs.parse(locationRef.current.search, {
        ignoreQueryPrefix: true,
      })
      const newValue: ValueType =
        typeof action === "function" ? action(value) : action
      const newValueStr = serialize(newValue)
      const defaultValueStr = serialize(defaultValue)
      import("react-facebook-pixel")
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.track("Search", newValue)
        })
      if (newValueStr === defaultValueStr) {
        const { [key]: toBeRemoved, ...remainingParams } = queryParams
        history.replace(
          `${locationRef.current.pathname}?${qs.stringify(remainingParams)}`
        )
      } else {
        history.replace(
          `${locationRef.current.pathname}?${qs.stringify({
            ...queryParams,
            [key]: newValueStr,
          })}`
        )
      }
    }

    return [value, setValue] as const
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return valueAndSetter
}

export const useRouterNullableNumberState = getRouterStateHook<number | null>(
  (value: any) =>
    value === "null" || (!isNaN(value) && !isNaN(parseFloat(value))),
  (value) => (value === "null" ? null : Number(value)),
  (value) => `${value}`
)

export const useRouterNullableDateState = getRouterStateHook<DateTime | null>(
  (value) =>
    value === "null" ||
    (/\d*-\d\d-\d\d/.test(value) && DateTime.fromISO(value).isValid),
  (value) => (value === "null" ? null : DateTime.fromISO(value)),
  (value) => (value ? value.toISODate() : "null")
)

export const useRouterStringState = getRouterStateHook<string>(
  () => true,
  (value) => value,
  (value) => value
)

export const useRouterStringSetState = getRouterStateHook<Record<string, true>>(
  () => true,
  (value) => Object.fromEntries(value.split(",").map((value) => [value, true])),
  (value) => Object.keys(value).join(",")
)

export const useRouterStringArraySetState = getRouterStateHook<
  Record<string, string[]>
>(
  () => true,
  (value) => JSON.parse(value),
  (value) => JSON.stringify(value)
)
