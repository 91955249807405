import { useTranslation } from "react-i18next"
import { Redirect } from "react-router"
import styled from "styled-components"
import { Button, Flex } from "../components"
import {
  deleteSearchWatch,
  getSearchWatch,
  getSearchWatchesByEmail,
} from "../services/developersService"
import { SearchWatch } from "../../types/apitypes"
import { useEffect, useState } from "react"
import { DateTime } from "luxon"
import { Loading } from "../Loading"
import { SearchWatchRecord } from "../../../server/types/dbtypes"

interface Props {
  id: string
}

export const UnsubscribePage = (props: Props) => {
  const [t] = useTranslation("common")
  const [data, setData] = useState<SearchWatch>()
  const [otherWatches, setOtherWatches] = useState<SearchWatchRecord[]>()
  const [message, setMessage] = useState("")
  const [isDeleted, setIsDeleted] = useState(false)
  const [allDeleted, setAllDeleted] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getSearchWatch(props.id)
        const searchWatchData = result.searchWatch as SearchWatch
        setData(searchWatchData)
        const byEmailResult = await getSearchWatchesByEmail(
          searchWatchData ? searchWatchData.email : ""
        )
        const otherSearchWatches = byEmailResult as SearchWatchRecord[]
        setOtherWatches(otherSearchWatches)
        setHasLoaded(true)
      } catch (e) {
        setHasLoaded(true)
      }
    }
    fetchData()
  }, [])

  const removeSearchWatch = async () => {
    if (!isDeleted) {
      try {
        const result = await deleteSearchWatch(props.id)
        if (result.status === 200) {
          setMessage(t("unsubscribe.successMessage"))
          setIsDeleted(true)
        } else {
          setMessage(t("unsubscribe.errorMessage"))
        }
      } catch (e) {
        setMessage(t("unsubscribe.errorMessage"))
      }
    }
  }

  const removeAll = async () => {
    if (!allDeleted) {
      try {
        otherWatches?.forEach(async (record) => {
          await deleteSearchWatch(record.id)
        })
        setMessage(t("unsubscribe.successMessageAll"))
        setIsDeleted(true)
        setAllDeleted(true)
      } catch (e) {
        setMessage(t("unsubscribe.errorMessage"))
      }
    }
  }

  if (!hasLoaded) {
    return <Loading />
  } else if (hasLoaded && !data) {
    return <Redirect to="/" />
  }

  return (
    <Flex row justify="center">
      <Flex column style={{ maxWidth: 800 }}>
        <Title>{t("unsubscribe.title")}</Title>
        <SubTitle>{t("unsubscribe.subTitle")}</SubTitle>
        <Flex
          row
          wrap
          rowSpacing="5px"
          spacing="10px"
          paddingTop="25px"
          paddingLeft="25px"
          paddingBottom="100px"
        >
          {data?.keywords?.map((keyword) => (
            <SelectionChip key={keyword}>{keyword}</SelectionChip>
          ))}
          {data?.locations.map((location) => (
            <SelectionChip key={location}>{location}</SelectionChip>
          ))}
          {data?.availability && (
            <SelectionChip>
              {DateTime.fromISO(data.availability.toString())
                .setLocale("fi")
                .toLocaleString({
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })}
            </SelectionChip>
          )}
          {data?.hourlyRate && (
            <SelectionChip>{`${data?.hourlyRate}€/h`}</SelectionChip>
          )}
          {data?.minExperience && (
            <SelectionChip>
              {t("filterRow.experienceLabel", {
                count: data?.minExperience,
              })}
            </SelectionChip>
          )}
        </Flex>
        <Flex paddingBottom="50px">
          {otherWatches && otherWatches.length > 1 && (
            <div>
              {t("unsubscribe.otherWatches", {
                count: otherWatches.length - 1,
              })}
            </div>
          )}
        </Flex>
        <FormLabelText>{message}</FormLabelText>
        <Flex row wrap rowSpacing="5px" spacing="10px">
          <Link href="/">
            <Button>{t("common.goBack")}</Button>
          </Link>
          <Button onClick={removeSearchWatch}>
            <div>{t("unsubscribe.title")}</div>
          </Button>
          {otherWatches && otherWatches.length > 1 && (
            <Button onClick={removeAll}>
              <div>{t("unsubscribe.unsubscribeAll")}</div>
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

const Title = styled.h1`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.heading};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 44px;
  max-width: 550px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.extraLarge};
    font-weight: ${({ theme }) => theme.weight.bold};
    line-height: 34px;
    max-width: 325px;
  }
`

const SubTitle = styled.h3`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 22px;
  max-width: 550px;
  margin-top: 30px;
`

const SelectionChip = styled.div`
  height: 25px;
  border: 1px solid ${({ theme }) => theme.color.primary};
  border-radius: 15px;
  color: ${({ theme }) => theme.color.primaryDark};
  padding: 0 15px;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  text-align: center;
  line-height: 25px;
  width: fit-content;
`

const Link = styled.a`
  text-decoration: none;
`
const FormLabelText = styled.div`
  margin-bottom: 10px;
  min-height: 20px;
`
