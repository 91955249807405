import styled, { css } from "styled-components"
interface LineSeparatorProps {
  blue?: boolean
}

export const LineSeparator = styled.div<LineSeparatorProps>`
  align-self: stretch;
  min-width: 1px;
  min-height: 1px;
  background-color: ${({ blue }) =>
    blue
      ? css`
          ${({ theme }) => theme.color.primary}
        `
      : css`
          ${({ theme }) => theme.color.lightGray}
        `};
`
