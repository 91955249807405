import { DateTime } from "luxon"
import React from "react"
import { useLocation } from "react-router-dom"
import { DeveloperRecord } from "../../server/types/dbtypes"
import { Profile } from "../types/apitypes"

import {
  useAvailabilityFilter,
  useHourlyRateFilter,
  useKeywordFilter,
  useLocationFilter,
  useMinExperienceFilter,
} from "./filters"
import { ProcessedProfile, processData } from "./processData"

export const useFilteredData = (data: ProcessedProfile[]) => {
  const location = useLocation()
  const [keywordFilter] = useKeywordFilter()
  const [locationFilter] = useLocationFilter()
  const [availabilityFilter] = useAvailabilityFilter()
  const [hourlyRateFilter] = useHourlyRateFilter()
  const [minExperienceFilter] = useMinExperienceFilter()

  const filteredData = React.useMemo(() => {
    const searchTermArrays = Object.values(keywordFilter)
    const locations = Object.keys(locationFilter)
    return filterData(
      data,
      searchTermArrays,
      locations,
      availabilityFilter,
      hourlyRateFilter,
      minExperienceFilter
    )
  }, [data, location.search])
  return filteredData
}

export const processProfiles = (data: DeveloperRecord[]) => {
  return processData(data.map((d) => JSON.parse(d.displayData) as Profile))
}

export const filterData = (
  data: ProcessedProfile[],
  keywords: string[][],
  locations: string[],
  availability: DateTime | null,
  hourlyRate: number | null,
  experience: number | null
) => {
  const availabilityFilterDayMillis =
    availability &&
    DateTime.fromISO(availability.toString()).startOf("day").toMillis()

  return data.filter((profile) => {
    if (
      keywords &&
      keywords.length > 0 &&
      !profileContainsAllKeywords(profile, keywords)
    ) {
      return false
    }

    if (
      locations &&
      locations.length > 0 &&
      !locations.includes(profile.location)
    ) {
      return false
    }
    if (availabilityFilterDayMillis !== null) {
      if (!profile.availableFrom) {
        return false
      }
      if (
        profile.availableFrom.startOf("day").toMillis() >
        availabilityFilterDayMillis
      ) {
        return false
      }
    }
    if (hourlyRate !== null) {
      if (profile.rate! > hourlyRate) {
        return false
      }
    }
    if (experience !== null) {
      if (profile.experience < experience) {
        return false
      }
    }
    return true
  })
}

const profileContainsAllKeywords = (
  profile: ProcessedProfile,
  keywordArrays: string[][]
) => {
  // this should contain all textual data of the profile
  const profileAsString = JSON.stringify([
    profile.id,
    ...profile.skills.map((skill) => skill.keyword),
    Object.values(profile.presentations).map((vals: any) => [
      vals.title,
      vals.description,
    ]),
    (profile.projects || ([] as any)).map((project: any) => [
      project.skills,
      Object.values(project.presentations).map((vals: any) => [
        vals.title,
        vals.description,
      ]),
    ]),
  ]).toLowerCase()
  for (const keywords of keywordArrays) {
    const synonymMatched = keywords.find((keyword) => {
      // match words starting with keyword
      const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
      const regex = new RegExp(`\\b${escapedKeyword.trim().toLowerCase()}`)
      return profileAsString.match(regex)
    })

    if (!synonymMatched) {
      return false
    }
  }
  return true
}
