import { Link } from "react-router-dom"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { EmailShareButton } from "react-share"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { Button, Flex, Icon, IconButton } from "./components"
import { ProcessedProfile } from "./processData"
import React, { useState } from "react"
import { Profile } from "./Profile"
import { useSnackbar } from "notistack"

interface Props {
  profile: ProcessedProfile
}

export const ResultsCard = (props: Props) => {
  const [t, i18n] = useTranslation("common")
  const presentation = props.profile.presentations[i18n.language]
    ? props.profile.presentations[i18n.language]
    : Object.values(props.profile.presentations)[0]
  const titleUrl = encodeURIComponent(presentation.title.replace(/\s+/g, "-"))
  const [isCopied, setCopied] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const onCopy = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  React.useEffect(() => {
    updateFavorite()
    window.addEventListener("storage", updateFavorite)
    return () => {
      window.removeEventListener("storage", updateFavorite)
    }
  }, [])

  const setFavorite = () => {
    let favorites = JSON.parse(
      localStorage.getItem("favorites") || "[]"
    ) as ProcessedProfile[]
    if (checkIsFavorite()) {
      favorites = favorites.filter((profile) => profile.id !== props.profile.id)
    } else {
      favorites.push(props.profile)
    }
    localStorage.setItem("favorites", JSON.stringify(favorites))
    window.dispatchEvent(new Event("storage"))
    updateFavorite()

    enqueueSnackbar(
      checkIsFavorite()
        ? t("common.favoritesAdded")
        : t("common.favoritesRemoved"),
      {
        variant: checkIsFavorite() ? "success" : "info",
      }
    )
  }

  const checkIsFavorite = () => {
    const favorites = JSON.parse(
      localStorage.getItem("favorites") || "[]"
    ) as ProcessedProfile[]
    return favorites.some((profile) => profile.id === props.profile.id)
  }

  const updateFavorite = () => {
    setIsFavorite(checkIsFavorite())
  }

  if (!presentation) {
    return null
  }

  return (
    <Card key={props.profile.id}>
      <Profile profile={props.profile} small shownSkills={6} />
      <ButtonRow>
        <Flex row wrap spacing="5px">
          <Flex paddingTop="3px">
            <CopyToClipboard
              key={props.profile.id}
              text={`${process.env.REACT_APP_VAHVISTUS_URL}/profile/${titleUrl}/${props.profile.id}`}
              onCopy={onCopy}
            >
              <IconButton
                title={t("resultsArea.copyTooltip")}
                key={props.profile.id}
                icon={isCopied ? "checkmark" : "content_copy"}
                size={20}
              />
            </CopyToClipboard>
          </Flex>
          <EmailShareButton
            url={`${process.env.REACT_APP_VAHVISTUS_URL}/profile/${titleUrl}/${props.profile.id}`}
            body={presentation.title}
            onClick={() => {}}
            openShareDialogOnClick
            style={{ outline: "none" }}
          >
            <IconButton
              title={t("resultsArea.emailTooltip")}
              icon="email"
              size={20}
            />
          </EmailShareButton>
          <FavoriteButton onClick={setFavorite}>
            <IconButton
              title={
                isFavorite
                  ? t("resultsArea.favoriteTooltipRemove")
                  : t("resultsArea.favoriteTooltipAdd")
              }
              icon={isFavorite ? "favorite_filled" : "favorite"}
              size={20}
            />
          </FavoriteButton>
        </Flex>
        <CVWrapper>
          <Button
            as={Link}
            to={`/profile/${titleUrl}/${props.profile.id}`}
            variant2
            style={{ float: "right", width: 180 }}
          >
            <div>{t("resultsArea.cvButtonLabel")}</div>
            <Icon icon="nuoli_linkki_oikea" size={20} />
          </Button>
        </CVWrapper>
      </ButtonRow>
    </Card>
  )
}

const SPACING = 30

const Card = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 30px;
  height: 683px;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 380px) {
    min-width: 380px;
  }

  @media screen and (min-width: 760px) {
    min-width: 380px;
    margin-right: 30px;
  }

  @media (min-width: 877px) {
    max-width: calc(50% - ${SPACING}px);
    margin-right: 30px;
  }

  @media (min-width: 1347px) {
    max-width: calc(33.33333% - ${SPACING}px);
    margin-right: 30px;
  }

  @media (min-width: 1757px) {
    max-width: calc(25% - ${SPACING}px);
    margin-right: 30px;
  }

  @media (min-width: 2307px) {
    max-width: calc(20% - ${SPACING}px);
    margin-right: 30px;
  }

  @media (min-width: 2717px) {
    max-width: calc(16.66666% - ${SPACING}px);
    margin-right: 30px;
  }
`

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
`
const CVWrapper = styled.div`
  flex: 1;
  float: right;
`

const FavoriteButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0px;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
`
