import * as React from "react"
import styled, { css } from "styled-components"

import { DateTime } from "luxon"

const ONE_DAY = { day: 1 } as const
const ONE_MONTH = { month: 1 } as const

const range = (begin: number, end: number): number[] =>
  Array(end - begin + 1)
    .fill(begin)
    .map((x, y) => x + y)

interface Props {
  month: number
  year: number
  selectedDate: DateTime
  onSelectDate: (date: DateTime) => void
}

export const DatePickerCalendar = React.memo(
  ({ month, year, selectedDate, onSelectDate }: Props) => {
    const startOfMonth = DateTime.fromObject({ month, year })
    const startOfCalendar = startOfMonth.startOf("week")

    const endOfMonth = startOfMonth.endOf("month")
    const endOfCalendar = endOfMonth.endOf("week")

    const lastDayOfLastMonth = startOfMonth.minus(ONE_DAY).get("day")

    const numPaddingStart =
      startOfMonth.get("weekday") - startOfCalendar.get("weekday")
    const paddingStart = (numPaddingStart > 0
      ? range(lastDayOfLastMonth - numPaddingStart + 1, lastDayOfLastMonth)
      : []
    ).map((day) => (
      <CalendarDayButton
        key={day}
        notCurrentMonth
        onClick={() => {
          // todo: navigate to previous month somehow (maybe refactor code)
          onSelectDate(startOfMonth.minus(ONE_MONTH).set({ day }))
        }}
      >
        {day}
      </CalendarDayButton>
    ))

    const numPaddingEnd =
      endOfCalendar.get("weekday") - endOfMonth.get("weekday")
    const paddingEnd = range(1, numPaddingEnd).map((day) => (
      <CalendarDayButton
        key={day}
        notCurrentMonth
        onClick={() => {
          // todo: navigate to next month somehow (maybe refactor code)
          onSelectDate(startOfMonth.plus(ONE_MONTH).set({ day }))
        }}
      >
        {day}
      </CalendarDayButton>
    ))

    const currentMonthEntries: React.ReactNode[] = range(
      1,
      endOfMonth.get("day")
    ).map((day) => (
      <CalendarDayButton
        key={day}
        selected={
          day === selectedDate.get("day") && month === selectedDate.get("month")
        }
        onClick={() => {
          onSelectDate(startOfMonth.set({ day }))
        }}
      >
        {day}
      </CalendarDayButton>
    ))

    const allEntries = [...paddingStart, ...currentMonthEntries, ...paddingEnd]
    const weeks = chunk(allEntries, 7)

    return (
      <CalendarTable>
        <thead>
          <tr>
            <th>Ma</th>
            <th>Ti</th>
            <th>Ke</th>
            <th>To</th>
            <th>Pe</th>
            <th>La</th>
            <th>Su</th>
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, index) => (
            <tr key={index}>
              {week.map((item, index) => (
                <td key={index}>{item}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </CalendarTable>
    )
  }
)

function chunk<T>(array: Array<T>, size: number) {
  const chunked_arr: Array<Array<T>> = []
  let index = 0
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index))
    index += size
  }
  return chunked_arr
}

const CalendarTable = styled.table`
  text-align: center;
  table-layout: fixed;
  thead {
    color: #bababa;
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.weight.medium};
    font-size: ${({ theme }) => theme.fontSize.tiny};
    line-height: 13px;
  }
  tbody {
    font-weight: ${({ theme }) => theme.weight.semiBold};
    font-size: ${({ theme }) => theme.fontSize.mediumSmall};
    line-height: 20px;
  }
  th,
  td {
    height: 36px;
  }
`

interface CalendarDayButtonProps {
  notCurrentMonth?: boolean
  selected?: boolean
}

const CalendarDayButton = styled.button<CalendarDayButtonProps>`
  border: none;
  background: ${({ theme, selected }) =>
    selected ? theme.color.primary : "transparent"};
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme, notCurrentMonth, selected }) =>
    notCurrentMonth
      ? "#BABABA"
      : selected
      ? theme.color.white
      : theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.mediumSmall};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 19.5px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  outline: 0;
  ${({ theme, selected }) =>
    !selected &&
    css`
      &:hover {
        background-color: ${theme.color.lightGray};
      }
    `};
`
