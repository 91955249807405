import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"

import { App } from "../common/app/App"
import { Providers } from "../common/app/Providers"
//import reportWebVitals from './reportWebVitals';

/**
 * Client side rendering entrypoint
 */

// Todo: during server side prerendering, inject initial data to a script tag in html, use it here if available
// const processedMockData = processData(MOCK_DATA)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Providers>
        <App />
      </Providers>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
)

// Todo: decide whether or not to use web vitals reporting in production:

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals(console.log);
