import { DateTime } from "luxon"

import { Profile } from "../types/apitypes"

export type ProcessedProfile = Omit<Profile, "availableFrom"> & {
  availableFrom: DateTime | null
}

export const processData = (data: Profile[]) => data.map(processProfile)

const processProfile = (profile: Profile): ProcessedProfile => ({
  ...profile,
  availableFrom:
    profile.availableFrom !== null
      ? DateTime.fromISO(profile.availableFrom).setLocale("fi")
      : null,
})
