import axios, { AxiosResponse } from "axios"
import {
  ContactInquiry,
  ContactJoin,
  Developers,
  Keyword,
  SearchWatch,
} from "../../types/apitypes"

// TODO API will return this
import keywords from "../../../client/keywords.json"
import { SearchWatchRecord } from "../../../server/types/dbtypes"

const vahvistusApiUrl = process.env.REACT_APP_VAHVISTUS_API_URL || ""

export const getDevelopers = async (): Promise<Developers> => {
  const response = await axios.get(`${vahvistusApiUrl}/api/data`, {
    responseType: "json",
  })

  return response.data.data
}

export const getKeywords = async (): Promise<Keyword[]> => {
  return keywords
}

export const postContactInquiry = async (
  data: ContactInquiry
): Promise<any> => {
  try {
    const response = await axios.post(`${vahvistusApiUrl}/api/contact`, data)
    return response
  } catch (error) {
    return error.response
  }
}

export const postContactJoin = async (data: ContactJoin): Promise<any> => {
  try {
    const response = await axios.post(`${vahvistusApiUrl}/api/join`, data)
    return response
  } catch (error) {
    return error.response
  }
}

export const postSearchWatch = async (data: SearchWatch): Promise<any> => {
  try {
    const response = await axios.post(
      `${vahvistusApiUrl}/api/searchwatch`,
      data
    )
    return response
  } catch (error) {
    return error.response
  }
}

export const checkSearchWatch = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${vahvistusApiUrl}/api/checkSearchWatches`
    )
    return response
  } catch (error) {
    return error.response
  }
}

export const getSearchWatch = async (
  id: string
): Promise<SearchWatchRecord> => {
  const response = await axios.get(
    `${vahvistusApiUrl}/api/searchWatchData/${id}`,
    {
      responseType: "json",
    }
  )

  return response.data
}

export const getSearchWatchesByEmail = async (
  email: string
): Promise<SearchWatchRecord[]> => {
  const response = await axios.post(
    `${vahvistusApiUrl}/api/searchWatchDataByEmail`,
    email,
    {
      responseType: "json",
    }
  )

  return response.data
}

export const deleteSearchWatch = async (id: string): Promise<AxiosResponse> => {
  const response = await axios.delete(
    `${vahvistusApiUrl}/api/searchWatchData/${id}`,
    {
      responseType: "json",
    }
  )

  return response
}
