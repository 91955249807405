import React, { useState } from "react"
import { NavLink, Route } from "react-router-dom"
import styled from "styled-components"
import logo from "../../common/app/icons/logo.svg"
import { useTranslation } from "react-i18next"
import { slide as Menu } from "react-burger-menu"
import { theme } from "./theme"

import { Flex, Icon, Separator, Visible } from "./components"
import { ProcessedProfile } from "./processData"
import { useSnackbar } from "notistack"

export const Header = () => {
  const [t, i18n] = useTranslation("common")
  const [isOpen, setIsOpen] = useState(false)
  const [isContactOpen, setIsContactOpen] = useState(false)
  const [favorites, setFavorites] = useState([] as ProcessedProfile[])
  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {
    window.addEventListener("storage", updateFavorite)
    return () => {
      window.removeEventListener("storage", updateFavorite)
    }
  }, [])

  const removeFavorite = (id: Number) => {
    const filteredFavorites = favorites.filter((profile) => profile.id !== id)
    setFavorites(filteredFavorites)
    localStorage.setItem("favorites", JSON.stringify(filteredFavorites))
    window.dispatchEvent(new Event("storage"))
    enqueueSnackbar(t("common.favoritesRemoved"), {
      variant: "info",
    })
  }

  const updateFavorite = () => {
    setFavorites(
      JSON.parse(
        localStorage.getItem("favorites") || "[]"
      ) as ProcessedProfile[]
    )
  }

  const handleStateChange = (state: boolean) => {
    setFavorites(
      JSON.parse(
        localStorage.getItem("favorites") || "[]"
      ) as ProcessedProfile[]
    )
    setIsOpen(state)
  }

  const getTitleUrl = (profile: ProcessedProfile) => {
    const presentation = profile.presentations[i18n.language]
      ? profile.presentations[i18n.language]
      : Object.values(profile.presentations)[0]
    const titleUrl = encodeURIComponent(presentation.title.replace(/\s+/g, "-"))
    return titleUrl
  }

  return (
    <HeaderArea>
      <Visible to="md">
        <Menu
          isOpen={isOpen}
          right
          styles={burgerMenuStyles}
          onStateChange={(state) => handleStateChange(state.isOpen)}
        >
          <StyledNavLink onClick={() => setIsOpen(false)} exact to="/">
            {t("header.findExpert")}
          </StyledNavLink>
          <StyledNavLink onClick={() => setIsOpen(false)} to="/join">
            {t("join.title")}
          </StyledNavLink>
          <StyledNavLink onClick={() => setIsOpen(false)} to="/FAQ">
            {t("header.faq")}
          </StyledNavLink>
          <ContactWrapper show>
            <StyledNavLink onClick={() => setIsOpen(false)} to="/contact">
              {t("common.contactCount", {
                count: favorites.length,
                context: `${favorites.length}`,
              })}
            </StyledNavLink>
            {favorites.length > 0 && (
              <ExpandButton onClick={() => setIsContactOpen(!isContactOpen)}>
                <Arrow flip={isContactOpen} color={theme.color.white} />
              </ExpandButton>
            )}
          </ContactWrapper>
          <ContactWrapper show={isContactOpen && favorites.length > 0}>
            <FavoritesLabel>{t("header.favorites")}</FavoritesLabel>
            {favorites.map((profile) => (
              <div key={profile.id}>
                <SmallStyledNavLink
                  onClick={() => setIsOpen(false)}
                  to={`/profile/${getTitleUrl(profile)}/${profile.id}`}
                >
                  {profile.presentations[i18n.language]
                    ? profile.presentations[i18n.language].title
                    : Object.values(profile.presentations)[0].title}
                </SmallStyledNavLink>
                <RemoveFavoriteButton
                  onClick={() => removeFavorite(profile.id)}
                >
                  <Icon size={12} icon="mobiilinavi_sulje" />
                </RemoveFavoriteButton>
              </div>
            ))}
          </ContactWrapper>
          {i18n.language === "en" && (
            <StyledButton
              onClick={() => {
                setIsOpen(false)
                i18n.changeLanguage("fi")
              }}
            >
              {t("header.changeLanguage")}
            </StyledButton>
          )}
          {i18n.language === "fi" && (
            <StyledButton
              onClick={() => {
                setIsOpen(false)
                i18n.changeLanguage("en")
              }}
            >
              {t("header.changeLanguage")}
            </StyledButton>
          )}
        </Menu>
        <Flex row align="center" style={{ height: 135 }}>
          <div>
            <a href="/">
              <img style={{ width: "90%" }} src={logo} alt="logo" />
            </a>
          </div>
        </Flex>
      </Visible>
      <Visible from="md">
        <Flex row align="center" style={{ height: 135 }}>
          <div>
            <a href="/">
              <img style={{ width: "100%" }} src={logo} alt="logo" />
            </a>
          </div>
          <Separator />
          <NavList>
            <NavListItem>
              <StyledNavLink exact to="/">
                {t("header.findExpert")}
              </StyledNavLink>
            </NavListItem>
            <NavListItem>
              <StyledNavLink to="/join">{t("join.title")}</StyledNavLink>
            </NavListItem>
            <NavListItem>
              <StyledNavLink to="/FAQ">{t("header.faq")}</StyledNavLink>
            </NavListItem>
            <NavListItem>
              <StyledNavLink to="/contact">
                {t("common.contactCount", {
                  count: favorites.length,
                  context: `${favorites.length}`,
                })}
              </StyledNavLink>
            </NavListItem>
            {i18n.language === "en" && (
              <StyledButton onClick={() => i18n.changeLanguage("fi")}>
                {t("header.changeLanguage")}
              </StyledButton>
            )}
            {i18n.language === "fi" && (
              <StyledButton onClick={() => i18n.changeLanguage("en")}>
                {t("header.changeLanguage")}
              </StyledButton>
            )}
          </NavList>
        </Flex>
      </Visible>
      <Route exact path="/">
        <Flex
          row
          align="center"
          justify="flex-start"
          style={{ height: 202.26 }}
        >
          <HeaderText>{t("header.description")}</HeaderText>
        </Flex>
      </Route>
    </HeaderArea>
  )
}

const HeaderArea = styled.header`
  background-color: ${({ theme }) => theme.color.primaryDark};
  color: ${({ theme }) => theme.color.white};
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.sm}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.lg}px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`

const HeaderText = styled.section`
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 25px;
  max-width: 850px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-weight: ${({ theme }) => theme.weight.medium};
    font-size: ${({ theme }) => theme.fontSize.small};
    line-height: 20px;
    max-width: 550px;
  }
`

const NavList = styled.ul`
  > li:not(:last-child) {
    border-right: 2px solid ${({ theme }) => theme.color.primary};
    padding-right: 25px;
  }
  > li:not(:first-child) {
    padding-left: 25px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-inline-start: 0px;
  }
`

const NavListItem = styled.li`
  display: inline;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: block;
    text-align: center;
    padding: 5px;
    border-right: 0px !important;
  }
`

const StyledNavLink = styled(NavLink).attrs(({ theme }) => ({
  activeStyle: { color: theme.color.gray },
}))`
  font-size: ${({ theme }) => theme.fontSize.mediumSmall};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  padding-bottom: 20px;
  &:hover {
    color: ${({ theme }) => theme.color.lightGray};
  }
  width: 100%;
`

const SmallStyledNavLink = styled(NavLink).attrs(({ theme }) => ({
  activeStyle: { color: theme.color.gray },
}))`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.weight.medium};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  display: inline-block;
  &:hover {
    color: ${({ theme }) => theme.color.lightGray};
  }
  width: 80%;
  padding-left: 10px;
`

const FavoritesLabel = styled.a`
  font-size: ${({ theme }) => theme.fontSize.mediumSmall};
  font-weight: ${({ theme }) => theme.weight.medium};
  color: ${({ theme }) => theme.color.white};
  text-decoration: underline;
  padding-bottom: 5px;
  display: inline-block;
  width: 100%;
  padding-left: 10px;
`

const StyledButton = styled.a.attrs(({ theme }) => ({
  activeStyle: { color: theme.color.gray },
}))`
  padding: 0 25px;
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.color.lightGray};
    cursor: pointer;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: ${({ theme }) => theme.fontSize.mediumSmall};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: block;
    text-align: center;
    padding: 0px;
    border-right: 0px !important;
  }
`

const RemoveFavoriteButton = styled.button.attrs(({ theme }) => ({
  activeStyle: { color: theme.color.gray },
}))`
  border: none;
  float: right;
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  background: ${({ theme }) => theme.color.primaryDark};
  &:hover {
    color: ${({ theme }) => theme.color.lightGray};
    cursor: pointer;
  }
`

const ExpandButton = styled.button.attrs(({ theme }) => ({
  activeStyle: { color: theme.color.gray },
}))`
  border: none;
  height: 20px;
  float: right;
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  background: ${({ theme }) => theme.color.primaryDark};
  &:hover {
    color: ${({ theme }) => theme.color.lightGray};
    cursor: pointer;
  }
`

const ContactWrapper = styled("div")<{ show?: boolean }>`
  width: 100%;
  margin-bottom: 20px;
  max-height: ${(props: any) => (props.show ? "100%" : "0%")};
  display: block;
  overflow: hidden;
  transition: all 0.5s ease-in;
`

const StyledArrowSvg = styled.svg<{ flip: boolean }>`
  margin-right: -2px;
  width: 15px;
  height: 7px;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: ${({ flip }) => (flip ? "" : "rotate(-90deg)")};
`

interface ArrowProps {
  color: string
  flip: boolean
}

const Arrow = (props: ArrowProps) => (
  <StyledArrowSvg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 11"
    flip={props.flip}
  >
    <path
      d="M9.5 10.5c-.4 0-.7-.1-1-.4L1 3.1C.4 2.5.3 1.6.9 1 1.5.4 2.4.3 3 .9l6.5 6 6.5-6c.6-.6 1.6-.5 2.1.1.6.6.5 1.6-.1 2.1l-7.5 7c-.3.3-.6.4-1 .4z"
      fill={props.color}
    />
  </StyledArrowSvg>
)

const burgerMenuStyles = {
  bmBurgerButton: {
    position: "absolute",
    width: "30px",
    height: "25px",
    right: "30px",
    top: "30px",
  },
  bmBurgerBars: {
    background: theme.color.white,
  },
  bmCross: {
    background: theme.color.white,
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: theme.color.primaryDark,
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmItemList: {
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
    outline: "none",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    marginLeft: "-100px",
  },
}
