import { ProcessedProfile } from "./processData"
import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

interface Props {
  shownProfileId: number
  profiles: ProcessedProfile[]
}

export const SeeAlsoCard = (props: Props) => {
  const [t, i18n] = useTranslation("common")
  const shownProfiles = props.profiles
    .filter((p) => p.id !== props.shownProfileId)
    .slice(0, 4)

  const getTitleUrl = (profile: ProcessedProfile) => {
    const presentation = profile.presentations[i18n.language]
      ? profile.presentations[i18n.language]
      : Object.values(profile.presentations)[0]
    const titleUrl = encodeURIComponent(presentation.title.replace(/\s+/g, "-"))
    return titleUrl
  }

  return (
    <CardHolder>
      <SeeAlso>
        <Title>{t("common.seeAlso")}</Title>
        <List>
          {shownProfiles.map((profile) => {
            const presentation = profile.presentations[i18n.language]
              ? profile.presentations[i18n.language]
              : Object.values(profile.presentations)[0]
            return (
              <ListItem key={profile.id}>
                <StyledLink
                  to={`/profile/${getTitleUrl(profile)}/${profile.id}`}
                >
                  {presentation.title}
                </StyledLink>
              </ListItem>
            )
          })}
        </List>
      </SeeAlso>
    </CardHolder>
  )
}

const CardHolder = styled.div`
  padding-top: 40px;
`

const SeeAlso = styled.div`
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 0;
  margin-top: 30px;
  margin-left: 5px;
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
`

const Title = styled.h2`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 22px;
  max-width: 550px;
  margin-top: 30px;
  padding-bottom: 5px;
  padding-top: 16px;
  text-align: center;
`
const List = styled.ul`
  text-decoration: none;
  padding: 5px 5px 10px 5px;
  margin: 0;
`

const ListItem = styled.li`
  list-style-type: none;
  margin-top: 5px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 20px;
  padding-left: 5px;
  justify-content: space-around;
`
