import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import { Button, Flex, Input } from "./components"
import { Dialog, DialogActions, DialogContent } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { DateTime } from "luxon"
import { postSearchWatch } from "./services/developersService"
import { SearchWatch } from "../types/apitypes"

interface Props {
  keywordFilter: Record<string, string[]>
  locationFilter: Record<string, true>
  availabilityFilter: DateTime | null
  hourlyRateFilter: number | null
  minExperienceFilter: number | null
  isOpen: boolean
  handleClose: any
}

export const SearchWatchDialog = ({
  keywordFilter,
  locationFilter,
  availabilityFilter,
  hourlyRateFilter,
  minExperienceFilter,
  isOpen,
  handleClose,
}: Props) => {
  const { handleSubmit, register, errors } = useForm()
  const [submittedData, setSubmittedData] = React.useState({ email: "" })
  const [t, i18n] = useTranslation("common")
  const didMountRef = useRef(false)
  const [message, setMessage] = useState("")
  const [showMessage, setShowMessage] = useState(false)

  const selections = []

  Object.keys(keywordFilter).map((keyword, index) => selections.push(keyword))

  Object.keys(locationFilter).map((keyword, index) => selections.push(keyword))

  if (availabilityFilter) {
    selections.push(
      availabilityFilter.setLocale("fi").toLocaleString({
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })
    )
  }

  if (hourlyRateFilter) {
    selections.push(`${hourlyRateFilter}€/h`)
  }

  if (minExperienceFilter) {
    selections.push(
      t("filterRow.experienceLabel", {
        count: minExperienceFilter,
      })
    )
  }

  useEffect(() => {
    if (didMountRef.current) {
      send()
    } else {
      didMountRef.current = true
    }
  }, [submittedData])

  const onSubmit = (data: React.SetStateAction<{ email: string }>) => {
    setSubmittedData(data)
  }

  const closeDialog = () => {
    handleClose()
    setMessage("")
  }

  const send = async () => {
    const data: SearchWatch = {
      email: submittedData["email"],
      keywords: Object.keys(keywordFilter),
      locations: Object.keys(locationFilter),
      availability: availabilityFilter?.toString() || "", // TODO asISO? test search watch with date filter
      hourlyRate: hourlyRateFilter,
      minExperience: minExperienceFilter,
      language: i18n.language,
    }
    const response = await postSearchWatch(data)
    if (response.status === 200) {
      closeDialog()
    } else if (response.status === 400) {
      const error = (response.data.errors && response.data.errors[0]) || {
        errorType: null,
      }
      if (error.errorType === "INVALID_EMAIL") {
        setMessage(t("form.emailInvalidResponse"))
        setShowMessage(true)
      } else {
        setMessage(t("searchWatch.errorSendingResponse"))
        setShowMessage(true)
      }
    } else {
      setMessage(t("searchWatch.retryResponse"))
      setShowMessage(true)
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: "550px" }}>
        <DialogContent>
          <Flex column spacing="20px">
            <FormTitle>{t("searchWatch.openDialogLabel")}</FormTitle>
            <InfoText>{t("searchWatch.dialogDescription")}</InfoText>

            <Flex row wrap rowSpacing="5px" spacing="10px">
              {selections.map((keyword, index) => (
                <SelectionChip key={index}>{keyword}</SelectionChip>
              ))}
            </Flex>

            <FormField label={t("form.emailLabel")}>
              <FormErrorText>{errors.email?.message}</FormErrorText>
              <Input
                type="text"
                placeholder={t("form.emailPlaceHolder")}
                name="email"
                id="email"
                ref={register({
                  required: {
                    message: t("form.emailRequired"),
                    value: true,
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("form.emailInvalid"),
                  },
                })}
              />
            </FormField>
          </Flex>
          {showMessage && <InfoText id="joinform">{message}</InfoText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            {t("searchWatch.cancelButtonLabel")}
          </Button>
          <Button variant2 type="submit">
            <div>{t("searchWatch.openDialogLabel")}</div>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const FormTitle = styled.h2`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 44px;
`

interface FormFieldProps {
  label: string
}

const FormField: React.FC<FormFieldProps> = (props) => (
  <FormLabel>
    <FormLabelText>{props.label}</FormLabelText>
    {props.children}
  </FormLabel>
)

const FormLabelText = styled.div`
  margin-bottom: 10px;
`

const FormErrorText = styled.div`
  margin-bottom: 10px;
  color: red;
`

const FormLabel = styled.label`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 13.41px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`
const InfoText = styled.div`
  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 17px;
`

const SelectionChip = styled.div`
  height: 25px;
  border: 1px solid ${({ theme }) => theme.color.primary};
  border-radius: 15px;
  color: ${({ theme }) => theme.color.primaryDark};
  padding: 0 15px;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  text-align: center;
  line-height: 25px;
  width: fit-content;
`
