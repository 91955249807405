import { Link } from "react-router-dom"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import { Button, Flex, Icon } from "../components"

export const FAQ = () => {
  const [t] = useTranslation("common")

  return (
    <Flex row justify="center">
      <Flex column style={{ maxWidth: 800 }}>
        <Title>{t("faq.title")}</Title>
        <QuestionText>{t("faq.question1")}</QuestionText>
        <AnswerText>{t("faq.answer1")}</AnswerText>
        <QuestionText>{t("faq.question2")}</QuestionText>
        <AnswerText>{t("faq.answer2")}</AnswerText>
        <QuestionText>{t("faq.question3")}</QuestionText>
        <AnswerText>
          {t("faq.answer3")}
          <Link to="/join">{t("faq.moreInfo")}</Link>
        </AnswerText>
        <QuestionText>{t("faq.question4")}</QuestionText>
        <AnswerText>{t("faq.answer4")}</AnswerText>
        <QuestionText>{t("faq.question5")}</QuestionText>
        <AnswerText>{t("faq.answer5")}</AnswerText>
        <Button
          as={Link}
          to="/"
          variant2
          style={{ width: 226.41, marginTop: 40 }}
        >
          <div>{t("faq.findExperts")}</div>
          <Icon icon="nuoli_linkki_oikea" size={20} />
        </Button>
      </Flex>
    </Flex>
  )
}

const Title = styled.h1`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.heading};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 44px;
  max-width: 550px;
`

const QuestionText = styled.h3`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 22px;
  max-width: 550px;
  margin-top: 30px;
`

const AnswerText = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.weight.regular};
  line-height: 17.07px;
  max-width: 550px;
  margin-top: 20px;
`
