import styled from "styled-components"

export const Input = styled.input`
  height: 50px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.primaryDark};
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: 0.2s all;
  font-family: ${({ theme }) => theme.fontFamily};
  padding: 0 20px;
`

export const InputCheckBox = styled.input`
  width: 25px;
  height: 25px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.primaryDark};
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: 0.2s all;
  font-family: ${({ theme }) => theme.fontFamily};
  padding: 0 20px;
`

export const InputFile = styled.input`
  height: 50px;
  transition: 0.2s all;
  font-family: ${({ theme }) => theme.fontFamily};
  padding: 0 2px;
`
