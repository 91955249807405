import styled from "styled-components"
import { Link } from "react-router-dom"
import CreatableSelect from "react-select/creatable"

import { Flex, Icon, Visible, flexSpacing } from "./components"
import { useKeywordFilter } from "./filters"
import { useTranslation } from "react-i18next"

// TODO read these once using developersService
import keywords from "../../client/keywords.json"
import React, { useState } from "react"
import { ProcessedProfile } from "./processData"

const POPULAR_TAGS = [
  "Full Stack",
  "Frontend",
  "UX",
  "Backend",
  "Mobile",
  "Cloud",
]

export const SearchRow = () => {
  const [, setKeywordFilter] = useKeywordFilter()
  const [t] = useTranslation("common")
  const [favorites, setFavorites] = useState([] as ProcessedProfile[])

  React.useEffect(() => {
    updateFavorite()
    window.addEventListener("storage", updateFavorite)
    return () => {
      window.removeEventListener("storage", updateFavorite)
    }
  }, [])

  const placeholder = {
    value: {
      label: t("searchRow.searchPlaceholder"),
      value: 1,
      synonyms: [""],
    },
  }

  const updateFavorite = () => {
    setFavorites(
      JSON.parse(
        localStorage.getItem("favorites") || "[]"
      ) as ProcessedProfile[]
    )
  }

  const findSynonyms = (searchTerm: string): string[] => {
    const found = keywords.find(
      (keyword) =>
        keyword.label.toLocaleLowerCase() === searchTerm.toLocaleLowerCase()
    )
    return found ? found.synonyms : [searchTerm]
  }

  const handleInputChange = (value: any) => {
    setKeywordFilter((previous) => ({
      ...previous,
      [value.label]: value.synonyms,
    }))
  }

  const clickSuggestion = (value: any) => {
    setKeywordFilter((previous) => ({
      ...previous,
      [value]: findSynonyms(value),
    }))
  }

  return (
    <StyledFlex row align="center">
      <Flex column spacing="29.25px">
        <Flex as="label" column spacing="15px">
          <Title>{t("header.findExpert")}</Title>
          <Visible to="sm">
            <CreatableSelect
              value={placeholder.value}
              onChange={handleInputChange}
              options={keywords}
              formatCreateLabel={(inputValue) => inputValue}
              onCreateOption={clickSuggestion}
            />
          </Visible>
          <Visible from="sm">
            <CreatableSelect
              value={placeholder.value}
              onChange={handleInputChange}
              options={keywords}
              formatCreateLabel={(inputValue) => inputValue}
              onCreateOption={clickSuggestion}
            />
          </Visible>
        </Flex>
        <Flex column spacing="14.14px">
          <SuggestionsTitle>{t("searchRow.suggestionsTitle")}</SuggestionsTitle>
          <Flex row spacing="10px" wrap rowSpacing="10px">
            {POPULAR_TAGS.map((tag, index) => (
              <SuggestionChip onClick={() => clickSuggestion(tag)} key={index}>
                {tag}
              </SuggestionChip>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <ContactLink to="/contact">
        <ContactText>
          {t("searchRow.contactButtonLabel", {
            count: favorites.length,
            context: `${favorites.length}`,
          })}
        </ContactText>
        <Icon icon="nuoli_linkki_oikea" size={42} style={{ height: 24 }} />
      </ContactLink>
    </StyledFlex>
  )
}

const StyledFlex = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    ${flexSpacing("20px")}
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    ${flexSpacing("126px")}
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.heading};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 44px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.extraLarge};
    font-weight: ${({ theme }) => theme.weight.bold};
    line-height: 34px;
  }
`

const SuggestionsTitle = styled.h3`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 22px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.weight.bold};
    line-height: 17px;
  }
`

const ContactLink = styled(Link)`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }

  padding-top: 10px;
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.color.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 154.25px;

  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const ContactText = styled.div`
  width: 187.66px;
  height: 60px;

  font-size: ${({ theme }) => theme.fontSize.mediumSmall};
  font-weight: ${({ theme }) => theme.weight.bold};
  line-height: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${({ theme }) => theme.fontSize.small};
    line-height: 17px;
    width: 500px;
  }
`

const SuggestionChip = styled.div`
  height: 25px;
  border: 1px solid ${({ theme }) => theme.color.primary};
  border-radius: 15px;
  color: ${({ theme }) => theme.color.primaryDark};
  padding: 0 15px;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  text-align: center;
  line-height: 25px;
  cursor: pointer;
`
