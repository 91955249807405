import * as React from "react"

import { FilterRow } from "./FilterRow"
import { Loading } from "./Loading"
import { ProcessedProfile } from "./processData"
import { ResultsArea } from "./ResultsArea"
import { SearchRow } from "./SearchRow"
import { useFilteredData } from "./useFilteredData"

interface Props {
  data: ProcessedProfile[]
  errorText: string
}

export const MainPage = (props: Props) => {
  const data = useFilteredData(props.data)
  return (
    <>
      <SearchRow />
      <FilterRow data={data} allData={props.data} />
      <ResultsArea data={data} />
      {props.data.length === 0 && props.errorText.trim() === "" && <Loading />}
    </>
  )
}
