import {
  useRouterNullableDateState,
  useRouterNullableNumberState,
  useRouterStringArraySetState,
  useRouterStringSetState,
} from "./components/hooks/useRouterState"

export const useKeywordFilter = () => useRouterStringArraySetState("search", {})
export const useLocationFilter = () => useRouterStringSetState("location", {})
export const useAvailabilityFilter = () =>
  useRouterNullableDateState("availableFrom", null)
export const useHourlyRateFilter = () =>
  useRouterNullableNumberState("hourlyRate", null)
export const useMinExperienceFilter = () =>
  useRouterNullableNumberState("minExperience", null)
