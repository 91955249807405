import { Flex } from "./components"
import { ProcessedProfile } from "./processData"
import { ResultsCard } from "./ResultsCard"
interface Props {
  data: ProcessedProfile[]
}

export const ResultsArea = (props: Props) => {
  const data = props.data
  // Todo: all same width (bottom row should not have wider cards)

  return (
    <Flex row align="stretch" wrap rowSpacing="20px">
      {data.map((profile) => (
        <ResultsCard key={profile.id} profile={profile} />
      ))}
    </Flex>
  )
}
