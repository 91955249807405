import { Flex, Icon, LineSeparator } from "./components"
import styled from "styled-components"
import { DateTime } from "luxon"
import { ProcessedProfile } from "./processData"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import React, { useState } from "react"
import { useSnackbar } from "notistack"

interface Props {
  profile: ProcessedProfile
  shownSkills?: number
  small: boolean
}

export const Profile = ({ profile, shownSkills, small }: Props) => {
  const [t, i18n] = useTranslation("common")
  const [isFavorite, setIsFavorite] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const skills = profile.skills.map((skill) => skill.keyword)
  const presentation = profile.presentations[i18n.language]
    ? profile.presentations[i18n.language]
    : Object.values(profile.presentations)[0]
  const titleUrl = encodeURIComponent(presentation.title.replace(/\s+/g, "-"))

  React.useEffect(() => {
    document.title =
      window.location.pathname === "/"
        ? `Vahvistus`
        : `Vahvistus - ${presentation.title}`
    updateFavorite()
    window.addEventListener("storage", updateFavorite)
    return () => {
      document.title = `Vahvistus`
      window.removeEventListener("storage", updateFavorite)
    }
  }, [])

  const setFavorite = () => {
    let favorites = JSON.parse(
      localStorage.getItem("favorites") || "[]"
    ) as ProcessedProfile[]
    if (checkIsFavorite()) {
      favorites = favorites.filter((favorite) => favorite.id !== profile.id)
    } else {
      favorites.push(profile)
    }
    localStorage.setItem("favorites", JSON.stringify(favorites))
    window.dispatchEvent(new Event("storage"))
    updateFavorite()

    enqueueSnackbar(
      checkIsFavorite()
        ? t("common.favoritesAdded")
        : t("common.favoritesRemoved"),
      {
        variant: checkIsFavorite() ? "success" : "info",
      }
    )
  }

  const checkIsFavorite = () => {
    const favorites = JSON.parse(
      localStorage.getItem("favorites") || "[]"
    ) as ProcessedProfile[]
    return favorites.some((favorite) => favorite.id === profile.id)
  }

  const updateFavorite = () => {
    setIsFavorite(checkIsFavorite())
  }

  return (
    <>
      <IdLabel>ID {`${profile.id}`.padStart(5, "0")}</IdLabel>
      {small && (
        <SmallTitle as={Link} to={`/profile/${titleUrl}/${profile.id}`}>
          {presentation.title}
        </SmallTitle>
      )}
      {!small && <Title>{presentation.title}</Title>}
      <Flex
        row
        wrap
        spacing={small ? "20px" : "25px"}
        rowSpacing="10.82px"
        paddingTop={small ? "25px" : "10px"}
        paddingBottom={small ? "25px" : "40px"}
      >
        {skills.map(
          (skill, index) =>
            (!shownSkills || index < shownSkills) && (
              <SkillText key={index}>{skill}</SkillText>
            )
        )}
        {shownSkills && skills.length > shownSkills && (
          <SkillText
            as={Link}
            to={`/profile/${titleUrl}/${profile.id}`}
            key={"otherSkills"}
          >
            {t("resultsArea.showMoreSkills", {
              count: skills.length - shownSkills,
            })}
          </SkillText>
        )}
      </Flex>
      <LineSeparator />
      <Flex
        row
        wrap
        spacing={small ? "10px" : "50px"}
        rowSpacing="20px"
        paddingTop={small ? "14px" : "25px"}
        paddingBottom={small ? "14px" : "25px"}
      >
        <SummaryEntry
          icon="tekija_sijainti"
          label={t("profilePage.location")}
          value={profile.location}
          small={small}
        />
        <SummaryEntry
          icon="tekija_saatavilla"
          label={t("profilePage.available")}
          value={
            (profile.availableFrom &&
              profile.availableFrom.toLocaleString(DateTime.DATE_SHORT)) ||
            t("profilePage.unknownAvailability")
          }
          small={small}
        />
        <SummaryEntry
          icon="tekija_tuntihinta"
          label={t("profilePage.price")}
          value={
            profile.rate ? `${profile.rate} €/h` : t("profilePage.unknownPrice")
          }
          small={small}
        />
        <SummaryEntry
          icon="tekija_tyokokemus"
          label={t("profilePage.experience")}
          value={t("profilePage.years", {
            count: profile.experience,
          })}
          small={small}
        />
        <SummaryEntry
          icon="tekija_kielet"
          label={t("profilePage.languages")}
          value={profile.languages.join(", ")}
          small={small}
        />
        {!small && (
          <Flex column style={{ width: 90 }}>
            <Icon
              style={{ cursor: "pointer" }}
              onClick={setFavorite}
              icon={isFavorite ? "favorite_filled_dark" : "favorite_dark"}
              size={30}
            />
            <SummaryLabel>
              {isFavorite
                ? t("profilePage.favorite")
                : t("profilePage.favoriteSelect")}
            </SummaryLabel>
          </Flex>
        )}
      </Flex>
      <LineSeparator />
      <Description small={small}>{presentation.description}</Description>
    </>
  )
}

const IdLabel = styled.div`
  line-height: 13px;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  color: ${({ theme }) => theme.color.black};
  font-weight: ${({ theme }) => theme.weight.semiBold};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    line-height: 11px;
    font-size: ${({ theme }) => theme.fontSize.extraTiny};
    font-weight: ${({ theme }) => theme.weight.medium};
  }
`

const SmallTitle = styled.div`
  margin-top: 10.08px;
  line-height: 34px;
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  color: ${({ theme }) => theme.color.primaryDark};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  text-decoration: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 12.08px;
    line-height: 29px;
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.weight.bold};
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.heading};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 44px;
  max-width: 550px;
  text-decoration: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.weight.bold};
    line-height: 29.26px;
    max-width: 325px;
  }
`

const SkillText = styled.div`
  line-height: 13px;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  color: ${({ theme }) => theme.color.black};
  font-weight: ${({ theme }) => theme.weight.semiBold};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    line-height: 11px;
    font-size: ${({ theme }) => theme.fontSize.extraTiny};
    font-weight: ${({ theme }) => theme.weight.medium};
  }
`

const SummaryLabel = styled.div`
  margin-top: 12px;

  line-height: 13px;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  color: ${({ theme }) => theme.color.primaryDark};
  font-weight: ${({ theme }) => theme.weight.semiBold};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 9px;
    line-height: 11px;
    font-size: ${({ theme }) => theme.fontSize.extraTiny};
    font-weight: ${({ theme }) => theme.weight.medium};
  }
`

const SummaryValue = styled.div<{ tiny: boolean }>`
  margin-top: 4px;

  line-height: 17px;
  font-size: ${({ theme, tiny }) =>
    tiny ? theme.fontSize.tiny : theme.fontSize.small};
  color: ${({ theme }) => theme.color.primaryDark};
  font-weight: ${({ theme }) => theme.weight.extraBold};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 6px;
    line-height: 14.63px;
    font-size: ${({ theme }) => theme.fontSize.extraSmall};
    font-weight: ${({ theme }) => theme.weight.bold};
  }
`

interface SummaryEntryProps {
  icon: string
  label: string
  value: string
  small: boolean
}

const SummaryEntry = ({ icon, label, value, small }: SummaryEntryProps) => (
  <Flex column style={{ width: small ? 90 : 80 }}>
    <Icon icon={icon} size={30} />
    <SummaryLabel>{label}</SummaryLabel>
    <SummaryValue tiny={value.length > 11 && !value.includes(" ") && small}>
      {value}
    </SummaryValue>
  </Flex>
)

const Description = styled.div<{ small: boolean }>`
  margin-top: ${({ small }) => (small ? "10px" : "25px")};
  margin-bottom: ${({ small }) => (small ? "10px" : "50px")};
  white-space: break-spaces;

  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 17px;

  overflow: hidden;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: ${({ small }) => (small ? "25px" : "34px")};
    font-weight: ${({ theme }) => theme.weight.medium};
    font-size: ${({ theme }) => theme.fontSize.extraSmall};
    line-height: 14.63px;
  }
`
