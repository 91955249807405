import React from "react"
import { Link, Redirect, useHistory } from "react-router-dom"
import styled from "styled-components"

import { Flex, Icon, LineSeparator } from "../components"
import { ProcessedProfile } from "../processData"
import { Loading } from "../Loading"
import { useTranslation } from "react-i18next"
import { Profile } from "../Profile"
import { SeeAlsoCard } from "../SeeAlsoCard"

interface Props {
  data: ProcessedProfile[]
  id: Number
  errorText: string
}

export const ProfilePage = (props: Props) => {
  const history = useHistory()
  const id = Number(props.id)
  const [t, i18n] = useTranslation("common")

  const profile = props.data.find((profile) => profile.id === id)

  React.useEffect(() => {
    import("react-facebook-pixel")
      .then((module) => module.default)
      .then((ReactPixel) => {
        ReactPixel.track("ViewContent", profile)
      })
  }, [])

  if (!props.errorText && !props.data.length) {
    return <Loading />
  }

  if (!profile) {
    return <Redirect to="/" />
  }

  const addToFavorites = () => {
    const favorites = JSON.parse(
      localStorage.getItem("favorites") || "[]"
    ) as ProcessedProfile[]
    if (!favorites.some((favorite) => favorite.id === profile.id)) {
      favorites.push(profile)
      localStorage.setItem("favorites", JSON.stringify(favorites))
    }
  }

  return (
    <Flex row wrap justify="center">
      <Flex column style={{ maxWidth: 800 }}>
        <Profile profile={profile} small={false} />
        {profile.projects && (
          <>
            <ContactLink onClick={addToFavorites} to="/contact">
              <ContactText>{t("contact.askMore")}</ContactText>
              <Icon icon="nuoli_linkki_oikea" size={20} />
            </ContactLink>
            <Subtitle>{t("profilePage.projects")}</Subtitle>
            <LineSeparator />
          </>
        )}

        {profile.projects?.map((item, index) => {
          const project = item.presentations[i18n.language]
            ? item.presentations[i18n.language]
            : Object.values(item.presentations)[0]
          return (
            <div key={index}>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectDuration>
                {item.start}-{item.end}
              </ProjectDuration>
              <Flex
                row
                wrap
                spacing="25px"
                rowSpacing="10.82px"
                paddingTop="10px"
              >
                {item.skills.map((skill, index) => (
                  <ProjectSkillText key={index}>{skill}</ProjectSkillText>
                ))}
              </Flex>
              <Description>{project.description}</Description>
            </div>
          )
        })}
        <ContactLink onClick={addToFavorites} to="/contact">
          <ContactText>{t("contact.askMore")}</ContactText>
          <Icon icon="nuoli_linkki_oikea" size={20} />
        </ContactLink>
        <StyledButton onClick={history?.goBack}>
          <Flex row align="center" spacing="10px" paddingTop="17px">
            <Icon icon="nuoli_linkki_vasen_sininen" size={18} />
            <LinkText>{t("profilePage.return")}</LinkText>
          </Flex>
        </StyledButton>
        <StyledLink to="/">
          <Flex
            row
            align="center"
            spacing="10px"
            paddingTop="10px"
            paddingBottom="75.7px"
          >
            <Icon icon="nuoli_linkki_vasen_sininen" size={18} />
            <LinkText>{t("profilePage.newSearch")}</LinkText>
          </Flex>
        </StyledLink>
      </Flex>
      <SeeAlsoCard profiles={props.data} shownProfileId={id} />
    </Flex>
  )
}

const Subtitle = styled.h2`
  margin-top: 60px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 29px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 17.89px;
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.weight.bold};
    line-height: 22px;
  }
`

const ProjectTitle = styled.h3`
  margin-top: 30px;
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 22px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 14px;
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.weight.bold};
    line-height: 17px;
  }
`

const ProjectDuration = styled.div`
  margin-top: 6px;
  margin-bottom: 21px;
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.weight.regular};
  line-height: 17px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 11.25px;
    margin-bottom: 16.6px;
    font-size: ${({ theme }) => theme.fontSize.extraSmall};
    font-weight: ${({ theme }) => theme.weight.medium};
    line-height: 14.63px;
  }
`

const ProjectSkillText = styled.div`
  line-height: 13px;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.weight.semiBold};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    line-height: 11px;
    font-size: ${({ theme }) => theme.fontSize.extraTiny};
    font-weight: ${({ theme }) => theme.weight.medium};
  }
`

const Description = styled.div`
  margin-top: 25px;
  margin-bottom: 50px;
  white-space: break-spaces;

  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 17px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 34px;
    font-weight: ${({ theme }) => theme.weight.medium};
    font-size: ${({ theme }) => theme.fontSize.extraSmall};
    line-height: 14.63px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const StyledButton = styled.div`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const LinkText = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.weight.extraBold};
  font-size: ${({ theme }) => theme.fontSize.tiny};
  line-height: 13px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.extraTiny};
    line-height: 11px;
  }
`

const ContactLink = styled(Link)`
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.color.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 50px;
  padding: 0px 16px;
  justify-content: space-around;

  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const ContactText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.weight.bold};
  line-height: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${({ theme }) => theme.fontSize.small};
    line-height: 17px;
    width: 500px;
  }
`
