import styled from "styled-components"
import icons from "../../icons"

interface IconProps {
  icon: string
  size: number
}

export const Icon = styled.img.attrs((props: IconProps) => ({
  //@ts-ignore
  src: icons[props.icon],
}))<IconProps>`
  && {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    font-size: ${(props) => props.size}px;
    line-height: ${(props) => props.size}px;
  }
`
