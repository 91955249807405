import color from "color"
import styled, { css } from "styled-components"

interface ButtonProps {
  // Todo: find out what is going on with button styles and which one to use or both
  variant2?: boolean
  as?: any
  to?: string
}

export const Button = styled.button.attrs((props) => ({
  type: props.type || "button",
}))<ButtonProps>`
  color: ${({ theme }) => theme.color.white};
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  text-decoration: none;
  border-width: 0;
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: all 0.1s ease-in-out;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.weight.extraBold};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) =>
      color(theme.color.primary).darken(0.1).toString()};
  }

  ${({ variant2: wide }) =>
    wide
      ? css`
          padding: 0 16px;
          justify-content: space-around;
        `
      : css`
          padding: 0 16px;
          > :not(:first-child) {
            margin-left: 11px;
          }
        `};
`
