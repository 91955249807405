import React from "react"
import styled from "styled-components"
import color from "color"

import { Separator } from "../Separator/Separator"
import { Collapse } from "../Collapse/Collapse"
import { theme } from "../../theme"
import { useTranslation } from "react-i18next"

interface SelectProps {
  label: string
  open: boolean
  setOpen: (open: React.SetStateAction<boolean>) => void
  filled: boolean
}

export const Select: React.FC<SelectProps> = ({
  children,
  label,
  filled,
  open,
  setOpen,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const listener = (e: any) => {
      if (!containerRef.current?.contains(e.target)) {
        setOpen(false)
      }
    }
    document.addEventListener("click", listener)
    return () => {
      document.removeEventListener("click", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container ref={containerRef}>
      <SelectContainer
        filled={filled}
        tabIndex={0}
        role="button"
        onClick={() => setOpen((o) => !o)}
      >
        {label}
        <Separator />
        <Arrow
          flip={open}
          color={filled ? theme.color.primary : theme.color.gray}
        />
      </SelectContainer>
      <div style={{ position: "relative" }}>
        <ContentCollapse open={open}>
          <div style={{ padding: 15 }}>{children}</div>
        </ContentCollapse>
      </div>
    </Container>
  )
}

interface SelectContainerProps {
  filled: boolean
}

export const SelectContainer = styled.div<SelectContainerProps>`
  min-width: 280px;
  height: 35px;
  background: ${({ theme }) => theme.color.white};
  border: 2px solid
    ${({ theme, filled }) =>
      filled ? theme.color.primary : theme.color.primaryDark};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${({ theme }) => theme.weight.extraBold};
  color: ${({ theme }) => theme.color.primaryDark};
  &:focus {
    outline: 0;
  }
  user-select: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    height: 30px;
    font-size: ${({ theme }) => theme.fontSize.extraSmall};
    font-weight: ${({ theme }) => theme.weight.bold};
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 0;
  margin: 5px;
  &:focus-within > ${SelectContainer} {
    border: 2px solid ${({ theme }) => theme.color.black};
  }
`

const ContentCollapse = styled(Collapse)`
  position: absolute;
  width: 100%;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.primaryDark};
  border-radius: ${({ theme }) => theme.borderRadius};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  //box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`

export const CloseSelectButton = styled.button.attrs((props) => {
  const [t] = useTranslation("common")
  const { type = "button", children = t("common.closeSelectButton") } = props
  return { type, children }
})`
  align-self: flex-start;
  text-decoration: none;
  border-width: 0;
  transition: all 0.1s ease-in-out;
  height: 35px;
  padding: 0px 21px;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.weight.extraBold};
  border: 2px solid ${({ theme }) => theme.color.primary};
  border-radius: ${({ theme }) => theme.borderRadius};
  font-family: ${({ theme }) => theme.fontFamily};
  margin-top: 26px;

  &:hover {
    background-color: ${({ theme }) =>
      color(theme.color.white).darken(0.04).toString()};
  }
`

const StyledArrowSvg = styled.svg<{ flip: boolean }>`
  margin-right: -2px;
  width: 15px;
  height: 7px;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: ${({ flip }) => (flip ? "rotate(180deg)" : "none")};
`

interface ArrowProps {
  color: string
  flip: boolean
}

const Arrow = (props: ArrowProps) => (
  <StyledArrowSvg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 11"
    flip={props.flip}
  >
    <path
      d="M9.5 10.5c-.4 0-.7-.1-1-.4L1 3.1C.4 2.5.3 1.6.9 1 1.5.4 2.4.3 3 .9l6.5 6 6.5-6c.6-.6 1.6-.5 2.1.1.6.6.5 1.6-.1 2.1l-7.5 7c-.3.3-.6.4-1 .4z"
      fill={props.color}
    />
  </StyledArrowSvg>
)
