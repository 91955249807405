import React from "react"
import styled from "styled-components"
import { DateTime } from "luxon"
import { useHistory, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import color from "color"

import {
  Checkbox,
  CloseSelectButton,
  DatePickerCalendar,
  FilterChip,
  Flex,
  Icon,
  Input,
  Select,
  flexSpacing,
} from "./components"
import {
  useAvailabilityFilter,
  useHourlyRateFilter,
  useKeywordFilter,
  useLocationFilter,
  useMinExperienceFilter,
} from "./filters"
import { ProcessedProfile } from "./processData"
import { SearchWatchDialog } from "./SearchWatchDialog"

interface Props {
  data: ProcessedProfile[]
  allData: ProcessedProfile[]
}

export const FilterRow = ({ data, allData }: Props) => {
  const history = useHistory()
  const location = useLocation()
  const [t] = useTranslation("common")

  const [keywordFilter, setKeywordFilter] = useKeywordFilter()
  const [locationFilter, setLocationFilter] = useLocationFilter()
  const [availabilityFilter, setAvailabilityFilter] = useAvailabilityFilter()
  const [hourlyRateFilter] = useHourlyRateFilter()
  const [minExperienceFilter, setMinExperienceFilter] = useMinExperienceFilter()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <FilterRowContainerFlex column spacing="22px">
      <FilterChipContainerFlex row align="center" wrap>
        <Title>{t("filterRow.title", { count: data.length })}</Title>
        <div>
          <Flex row align="center" spacing="20px" wrap rowSpacing="20px">
            {Object.keys(keywordFilter).map((keyword) => (
              <FilterChip
                key={keyword}
                onDelete={() => {
                  setKeywordFilter((previous) => {
                    const { [keyword]: toBeRemoved, ...remaining } = previous
                    return remaining
                  })
                }}
              >
                {keyword}
              </FilterChip>
            ))}
            {Object.keys(locationFilter).map((location) => (
              <FilterChip
                key={location}
                onDelete={() => {
                  setLocationFilter((previous) => {
                    const { [location]: toBeRemoved, ...remaining } = previous
                    return remaining
                  })
                }}
              >
                {location}
              </FilterChip>
            ))}
            {availabilityFilter && (
              <FilterChip
                onDelete={() => {
                  setAvailabilityFilter(null)
                }}
              >
                {availabilityFilter.setLocale("fi").toLocaleString({
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })}
              </FilterChip>
            )}
            {/* {hourlyRateFilter && (
              <FilterChip
                onDelete={() => {
                  setHourlyRateFilter(null)
                }}
              >
                {`${hourlyRateFilter}€/h`}
              </FilterChip>
            )} */}
            {minExperienceFilter && (
              <FilterChip
                onDelete={() => {
                  setMinExperienceFilter(null)
                }}
              >
                {t("filterRow.experienceLabel", {
                  count: minExperienceFilter,
                })}
              </FilterChip>
            )}
            {(availabilityFilter ||
              // hourlyRateFilter ||
              minExperienceFilter ||
              Object.keys(locationFilter).length > 0 ||
              Object.keys(keywordFilter).length > 0) && (
              <>
                <ClearSearchButton
                  onClick={() => {
                    history.replace(`${location.pathname}`)
                  }}
                >
                  {t("filterRow.clearSelection")}
                </ClearSearchButton>
                <OpenDialogButton
                  onClick={() => {
                    handleClickOpen()
                  }}
                >
                  {t("searchWatch.openDialogLabel")}
                </OpenDialogButton>
              </>
            )}
          </Flex>
        </div>
      </FilterChipContainerFlex>
      <LocationFilterContainer>
        <LocationFilterSelect
          data={allData}
          value={locationFilter}
          setValue={setLocationFilter}
        />
        <AvailabilityFilterSelect
          value={availabilityFilter}
          setValue={setAvailabilityFilter}
        />
        {/* <HourlyRateFilterSelect
          value={hourlyRateFilter}
          setValue={setHourlyRateFilter}
        /> */}
        <ExperienceFilterSelect
          value={minExperienceFilter}
          setValue={setMinExperienceFilter}
        />
      </LocationFilterContainer>
      <SearchWatchDialog
        keywordFilter={keywordFilter}
        locationFilter={locationFilter}
        availabilityFilter={availabilityFilter}
        hourlyRateFilter={hourlyRateFilter}
        minExperienceFilter={minExperienceFilter}
        isOpen={open}
        handleClose={handleClose}
      />
    </FilterRowContainerFlex>
  )
}

interface FilterSelectProps<T> {
  data?: ProcessedProfile[]
  value: T | null
  setValue: (value: T) => void
}

const LocationFilterSelect = (
  props: FilterSelectProps<Record<string, true>>
) => {
  const [open, setOpen, internalValue, setInternalValue] = useFilterSelectState<
    Record<string, true>
  >(props.value || {})
  const filled = Object.keys(props.value || {}).length > 0
  const [t] = useTranslation("common")
  const locations = ["Uusimaa", "Pirkanmaa", "Pohjois-Pohjanmaa"]
  props.data?.forEach((x) => {
    if (!locations.includes(x.location) && x.location) {
      locations.push(x.location)
    }
  })

  return (
    <Select
      label={t("filterRow.location", {
        count: Object.keys(props.value || {}).length,
        context: filled ? "many" : "zero",
      })}
      filled={filled}
      open={open}
      setOpen={setOpen}
    >
      <Flex column>
        <Flex column spacing="9px">
          {locations.map((location, key) => {
            return (
              <LocationCheckbox
                label={location}
                key={key}
                state={internalValue}
                setState={setInternalValue}
              />
            )
          })}
        </Flex>
        {/* Todo: show more button */}
        <CloseSelectButton
          onClick={(e: any) => {
            props.setValue(internalValue)
            setOpen(false)
            e.target.blur()
          }}
        />
      </Flex>
    </Select>
  )
}

interface LocationCheckboxProps {
  label: string
  state: Record<string, true>
  setState: (action: React.SetStateAction<Record<string, true>>) => void
}

const LocationCheckbox = ({
  label,
  state,
  setState,
}: LocationCheckboxProps) => {
  return (
    <Checkbox
      label={label}
      checked={!!state[label]}
      onChange={(e) => {
        if (e.target.checked) {
          setState((previousState) => ({
            ...previousState,
            [label]: true,
          }))
        } else {
          setState((previousState) => {
            const { [label]: toBeRemoved, ...remainingItems } = previousState
            return remainingItems
          })
        }
      }}
    />
  )
}

const AvailabilityFilterSelect = (props: FilterSelectProps<DateTime>) => {
  const [open, setOpen, internalValue, setInternalValue] = useFilterSelectState(
    props.value || DateTime.local()
  )

  const [calendarViewDate, setCalendarViewDate] = React.useState<DateTime>(
    DateTime.local().setLocale("fi").startOf("month")
  )
  const [t] = useTranslation("common")

  return (
    <Select
      label={
        props.value
          ? t("filterRow.startDate", {
              date: props.value
                ?.setLocale("fi")
                .toLocaleString(DateTime.DATE_SHORT),
            })
          : t("filterRow.startDatePlaceholder")
      }
      filled={!!props.value}
      open={open}
      setOpen={setOpen}
    >
      <Flex column>
        <Flex row align="center">
          <CalendarArrowButton
            onClick={() => setCalendarViewDate((date) => date.minus(ONE_MONTH))}
          >
            <Icon size={18} icon="nuoli_kalenteri_vasen" />
          </CalendarArrowButton>
          <DateText>
            {calendarViewDate.toLocaleString({
              year: "numeric",
              month: "long",
            })}
          </DateText>
          <CalendarArrowButton
            onClick={() => setCalendarViewDate((date) => date.plus(ONE_MONTH))}
          >
            <Icon size={18} icon="nuoli_kalenteri_oikea" />
          </CalendarArrowButton>
        </Flex>
        <DatePickerCalendar
          month={calendarViewDate.get("month")}
          year={calendarViewDate.get("year")}
          selectedDate={internalValue}
          onSelectDate={setInternalValue}
        />
      </Flex>
      <CloseSelectButton
        onClick={(e: any) => {
          props.setValue(internalValue)
          setOpen(false)
          e.target.blur()
        }}
      />
    </Select>
  )
}

// const HourlyRateFilterSelect = (props: FilterSelectProps<number>) => {
//   const [open, setOpen, internalValue, setInternalValue] = useFilterSelectState(
//     props.value || 80
//   )
//   const [t] = useTranslation("common")

//   return (
//     <Select
//       label={props.value ? `Max. ${props.value}€/h` : t("filterRow.hourlyRate")}
//       open={open}
//       setOpen={setOpen}
//       filled={!!props.value}
//     >
//       <Flex column align="stretch" spacing="30px">
//         <Flex row align="center" spacing="8px">
//           <span>Max.</span>
//           <ShortInput
//             value={internalValue}
//             onChange={(e) => {
//               if (isNaN(e.target.value as any)) {
//                 return
//               }
//               setInternalValue(Number(e.target.value))
//             }}
//           />
//           <span>€/h</span>
//         </Flex>
//         <input
//           type="range"
//           min="60"
//           max="200"
//           value={internalValue}
//           onChange={(e) => {
//             setInternalValue(Number(e.target.value))
//           }}
//         />
//         <CloseSelectButton
//           onClick={(e: any) => {
//             props.setValue(internalValue)
//             setOpen(false)
//             e.target.blur()
//           }}
//         />
//       </Flex>
//     </Select>
//   )
// }

const ExperienceFilterSelect = (props: FilterSelectProps<number>) => {
  const [open, setOpen, internalValue, setInternalValue] = useFilterSelectState(
    props.value || 5
  )
  const [t] = useTranslation("common")

  return (
    <Select
      label={
        props.value
          ? t("filterRow.experienceLabel", {
              count: props.value,
            })
          : t("filterRow.experience")
      }
      filled={!!props.value}
      open={open}
      setOpen={setOpen}
    >
      <Flex column align="stretch" spacing="30px">
        <Flex row align="center" spacing="8px">
          <span>Min.</span>
          <ShortInput
            value={internalValue}
            onChange={(e) => {
              if (isNaN(e.target.value as any)) {
                return
              }
              setInternalValue(Number(e.target.value))
            }}
          />
          <span>
            {t("filterRow.experienceFilter", {
              count: internalValue,
            })}
          </span>
        </Flex>
        <input
          type="range"
          min="5"
          max="20"
          value={internalValue}
          onChange={(e) => {
            setInternalValue(Number(e.target.value))
          }}
        />
        <CloseSelectButton
          onClick={(e: any) => {
            props.setValue(internalValue)
            setOpen(false)
            e.target.blur()
          }}
        />
      </Flex>
    </Select>
  )
}

interface FilterSelectState<T> {
  open: boolean
  internalValue: T
}

const useFilterSelectState = <T extends any>(initialValue: T) => {
  const [state, setState] = React.useState<FilterSelectState<T>>({
    open: false,
    internalValue: initialValue,
  })

  const setOpen = (action: React.SetStateAction<boolean>) =>
    setState((previousState) => ({
      open: typeof action === "function" ? action(previousState.open) : action,
      internalValue: initialValue,
    }))

  const setInternalValue = (action: React.SetStateAction<T>) =>
    setState((previousState) => ({
      open: previousState.open,
      internalValue:
        typeof action === "function"
          ? //@ts-ignore
            action(previousState.internalValue)
          : action,
    }))
  const { open, internalValue } = state
  return [open, setOpen, internalValue, setInternalValue] as const
}

const ONE_MONTH = { month: 1 } as const

const FilterRowContainerFlex = styled(Flex)`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 72px;
    margin-bottom: 40px;
  }

  margin-top: 46px;
  margin-bottom: 20px;
`

const FilterChipContainerFlex = styled(Flex)`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    ${flexSpacing("60px", "10px")}
    padding-left: 0px;
  }
`

const LocationFilterContainer = styled.div`
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    > :not(:last-child) {
      margin-bottom: 5px;
    }
  }
`

const DateText = styled.div`
  flex: 1;
  text-align: center;
  text-transform: capitalize;
  font-weight: ${({ theme }) => theme.weight.bold};
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.small};
`

const CalendarArrowButton = styled.button`
  border: 0;
  background: transparent;
  border-radius: ${({ theme }) => theme.borderRadius};
`

const Title = styled.h2`
  color: ${({ theme }) => theme.color.primaryDark};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 29px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.weight.bold};
    line-height: 22px;
    margin-bottom: 16.44px;
  }
`

const ClearSearchButton = styled.button`
  color: ${({ theme }) => theme.color.white};
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.color.red};
  font-size: ${({ theme }) => theme.fontSize.small};
  text-decoration: none;
  border-width: 0;
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: all 0.1s ease-in-out;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.weight.extraBold};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) =>
      color(theme.color.red).darken(0.1).toString()};
  }
`

const OpenDialogButton = styled.button`
  color: ${({ theme }) => theme.color.white};
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  text-decoration: none;
  border-width: 0;
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: all 0.1s ease-in-out;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.weight.extraBold};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) =>
      color(theme.color.primary).darken(0.1).toString()};
  }
`

const ShortInput = styled(Input)`
  width: 60px;
  padding: 0 8px;
  text-align: center;
`
